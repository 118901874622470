import { Layout, Menu } from "antd";
import React, { FC, ReactElement, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { StatusPropObj } from "shared-modules/general-components/StatusTag";
import useGetUserPages from "shared-modules/general-hooks/useGetUserPages";
import { MainHeader } from "./MainHeader";
const { Sider, Content } = Layout;

type Props = {
  title: ReactElement | string;
  subTitle?: ReactElement | string;
  menuItems?: any[];
  statusTags?: StatusPropObj[] | ReactElement;
  id?: string;
};

const SiderComponent = (props: { siderOpened: boolean }) => {
  const userPages = useGetUserPages();
  const match = useRouteMatch();
  const selectedPath = match.path;
  return (
    <Sider
      trigger={null}
      breakpoint="md"
      collapsedWidth="0"
      collapsible
      collapsed={!props.siderOpened}
    >
      <div style={{ padding: "15px 30px 10px 30px", textAlign: "center" }}>
        <img
          src="/img/logo140.svg"
          alt="Logo"
          style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: -10 }}
        />
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[selectedPath]}>
        {!!userPages &&
          userPages
            .filter(({ linkText }) => linkText)
            .map(({ path, linkText }) => (
              <Menu.Item key={path}>
                <Link to={path}>{linkText}</Link>
              </Menu.Item>
            ))}
      </Menu>
    </Sider>
  );
};

export const StandardPage: FC<Props> = (props) => {
  const [siderOpened, setSiderOpened] = useState(false);
  const toggleSider = () => setSiderOpened((old) => !old);
  const { title, subTitle, menuItems, statusTags, id, ...rest } = props;
  return (
    <Layout style={{ height: "100vh" }} className="site-layout-background">
      <SiderComponent siderOpened={siderOpened} />
      <Layout className="site-layout">
        <Content
          id={id}
          className="site-layout-background"
          style={{
            height: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MainHeader
            toggleSider={toggleSider}
            siderOpened={siderOpened}
            title={title}
            subTitle={subTitle}
            menuItems={menuItems}
            statusTags={statusTags}
            {...rest}
          />
          <div
            style={{
              flexGrow: 1,
              padding: "20px 50px",
              overflowY: "auto",
            }}
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
