import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  DollarCircleOutlined,
  EditOutlined,
  FileOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  StarOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import {
  VENDOR_INVOICE_STATUSES,
  VENDOR_PROPOSAL_STAGES,
} from "shared-modules/globals/globals";
import color from "shared-modules/helpers/colors";
import { ReactElement } from "react";
import { Opportunity } from "shared-modules/types/types";
import { ReactFCC } from "shared-modules/shared-types";

export type StatusType =
  | keyof typeof VENDOR_INVOICE_STATUSES
  | keyof typeof VENDOR_PROPOSAL_STAGES
  | Opportunity["OpportunityStatus"]
  | "INSTALLMENT"
  | "ERROR"
  | "ACCEPTED"
  | "EXPIRED";

export type StatusPropObj = {
  isUppercase?: boolean;
  icon?: ReactElement;
  mainColor?: string;
  bgColor?: string;
  content?: string | number | ReactElement;
};

type StatusPropsType = (status?: StatusType) => StatusPropObj;

type StatusIconPropType = {
  status: StatusType;
};

type StatusTagPropType = {
  status: StatusType;
};

const tagColors = {
  green: {
    mainColor: color("green", "dk"),
    bgColor: color("green", "lt"),
  },
  yellow: {
    mainColor: color("yellow", "xxdk"),
    bgColor: color("yellow", "lt"),
  },
  grey: {
    mainColor: color("grey"),
    bgColor: color("grey", "xlt"),
  },
  red: {
    mainColor: color("red"),
    bgColor: color("red", "xlt"),
  },
};

export const statusProps: StatusPropsType = (status) => {
  switch (status) {
    case "New":
      return {
        icon: <FileOutlined style={{ color: tagColors.yellow.mainColor }} />,
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "NEW",
      };
    case "BIDDING":
    case "Bidding":
      return {
        icon: (
          <DollarCircleOutlined style={{ color: tagColors.yellow.mainColor }} />
        ),
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "BIDDING",
      };
    case "EDITING":
      return {
        icon: <EditOutlined style={{ color: tagColors.yellow.mainColor }} />,
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "EDITING",
      };
    case "SUBMITTED":
      return {
        icon: <SendOutlined style={{ color: tagColors.yellow.mainColor }} />,
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "SUBMITTED",
      };
    case "REVIEWED":
      return {
        icon: <SendOutlined style={{ color: tagColors.yellow.mainColor }} />,
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "REVIEWED",
      };
    case "Pending Approval":
      return {
        icon: (
          <QuestionCircleOutlined
            style={{ color: tagColors.yellow.mainColor }}
          />
        ),
        mainColor: tagColors.yellow.mainColor,
        bgColor: tagColors.yellow.bgColor,
        content: "PENDING APPROVAL",
      };
    case "APPROVED":
    case "Approved":
      return {
        icon: (
          <CheckCircleOutlined style={{ color: tagColors.green.mainColor }} />
        ),
        mainColor: tagColors.green.mainColor,
        bgColor: tagColors.green.bgColor,
        content: "APPROVED",
      };
    case "ACCEPTED":
      return {
        icon: (
          <CheckCircleOutlined style={{ color: tagColors.green.mainColor }} />
        ),
        mainColor: tagColors.green.mainColor,
        bgColor: tagColors.green.bgColor,
        content: "ACCEPTED",
      };
    case "REJECTED":
      return {
        icon: (
          <CloseCircleOutlined style={{ color: tagColors.red.mainColor }} />
        ),
        mainColor: tagColors.red.mainColor,
        bgColor: tagColors.red.bgColor,
        content: "REJECTED",
      };
    case "BIDDING_COMPLETE":
      return {
        icon: (
          <CloseCircleTwoTone style={{ color: tagColors.grey.mainColor }} />
        ),
        mainColor: tagColors.grey.mainColor,
        bgColor: tagColors.grey.bgColor,
        content: "BIDDING COMPLETE",
      };
    case "Denied":
      return {
        icon: (
          <CloseCircleOutlined style={{ color: tagColors.red.mainColor }} />
        ),
        mainColor: tagColors.red.mainColor,
        bgColor: tagColors.red.bgColor,
        content: "DENIED",
      };
    case "Delivered":
      return {
        icon: (
          <CheckCircleOutlined style={{ color: tagColors.green.mainColor }} />
        ),
        mainColor: tagColors.green.mainColor,
        bgColor: tagColors.green.bgColor,
        content: "DELIVERED",
      };
    case "Lost":
      return {
        icon: <StopOutlined style={{ color: tagColors.grey.mainColor }} />,
        mainColor: tagColors.grey.mainColor,
        bgColor: tagColors.grey.bgColor,
        content: "LOST",
      };
    case "Won":
      return {
        icon: <StarOutlined style={{ color: tagColors.green.mainColor }} />,
        mainColor: tagColors.green.mainColor,
        bgColor: tagColors.green.bgColor,
        content: "WON",
      };
    case "INSTALLMENT":
      return {
        icon: (
          <DollarCircleOutlined style={{ color: tagColors.grey.mainColor }} />
        ),
        mainColor: tagColors.grey.mainColor,
        bgColor: tagColors.grey.bgColor,
        content: "INSTALLMENT",
      };
    case "EXPIRED":
      return {
        icon: <WarningOutlined style={{ color: tagColors.red.mainColor }} />,
        mainColor: tagColors.red.mainColor,
        bgColor: tagColors.red.bgColor,
        content: "EXPIRED",
      };

    case "ERROR":
      return {
        icon: <WarningOutlined style={{ color: tagColors.red.mainColor }} />,
        mainColor: tagColors.red.mainColor,
        bgColor: tagColors.red.bgColor,
        content: "ERROR",
      };
    default:
      return {};
  }
};

const StatusIcon: ReactFCC<StatusIconPropType> = ({ status }) => {
  const currStatusProps = statusProps(status);

  return (
    <div
      style={{
        marginRight: "5px",
        display: "flex",
        alignItems: "center",
        color: currStatusProps.mainColor,
      }}
    >
      {currStatusProps.icon}
    </div>
  );
};

const StatusTag: ReactFCC<StatusTagPropType> = ({ status, children }) => {
  const currStatusProps = statusProps(status);

  const textColorStyle = currStatusProps.bgColor
    ? currStatusProps.mainColor
    : "white";

  const borderStyle =
    currStatusProps.bgColor && `1px solid ${currStatusProps.mainColor}`;

  const bgColor = currStatusProps.bgColor
    ? currStatusProps.bgColor
    : currStatusProps.mainColor;

  return (
    !!status && (
      <Tag
        style={{
          display: "inline-flex",
          border: borderStyle,
          color: textColorStyle,
          backgroundColor: bgColor,
        }}
      >
        <StatusIcon status={status} />
        {currStatusProps.content ? currStatusProps.content : children}
      </Tag>
    )
  );
};

export default StatusTag;
