export const PROPOSAL_STAGES = {

  // permissions in workTickets:vendor
  BIDDING: 'Bidding',
  SUBMITTED: 'Submitted',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ACTIVE: 'In Progress',
  COMPLETED: 'Complete',
}

export const role = { context: { headers: { "x-hasura-role": "vendor" } } }