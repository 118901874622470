import React from "react";
import color from "shared-modules/helpers/colors";

const TitleDivider = () => {
  return (
    <span
      style={{
        fontSize: 20,
        marginTop: -3,
        marginRight: 5,
        marginLeft: 5,
        color: color("grey", "lt"),
      }}
    >
      |
    </span>
  );
};

export default TitleDivider;
