import { Auth } from "aws-amplify"

export const isNumeric = str => !isNaN(str) && !isNaN(parseFloat(str))

export const isNullish = val => val == null

export const displayIfExistsWithLB = (strings, val) => 
  val ? 
    strings[0] === 'new_line' ? <><br />{val}</> : val
    : false
export const toDollars = val => Number.isNaN(val) ? '' : Number(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' })

export const dateStringToReadable = (dateString) => {
  // takes a date with format yyyy-mm-dd and converts to m/d/yyyy
  if(!(
    // is string
    (typeof dateString === 'string' || dateString instanceof String)
    // valid input
    && dateString.match(/^\d{4}-\d{2}-\d{2}$/)
    )
  ) return undefined;
  const [y, m, d] = dateString.split('-')
  return `${Number(m)}/${Number(d)}/${Number(y)}`
}
export const userSessionRemainingSeconds = userExpiresSeconds => {
  let expiresMS;
  if (!isNullish(userExpiresSeconds)) expiresMS = userExpiresSeconds * 1000
  else if (!isNullish(localStorage.getItem('expiresMS'))) expiresMS = localStorage.getItem('expiresMS')
  else return undefined
  return (expiresMS - Date.now()) / 1000
}

export const refreshUserFromAmplify = async (setUser, userExpiresSeconds) => {
  if (process.env.NODE_ENV === 'development') console.log('refreshUserFromAmplify RAN')
  try {
    // if (isNullish(remainingSeconds) || remainingSeconds < 1) {
    //   if (process.env.NODE_ENV === 'development') console.log('remaining seconds: ', remainingSeconds, ' userExpiresSeconds', userExpiresSeconds)
    //   return Auth.signOut().then(() => setUser({}))
    // }
    const userResponse = await Auth.currentAuthenticatedUser()
    setUser(userResponse)
    
  } catch (error) {
    console.error('refresh user failed: ', error.message)
    signoutUserFromAmplify(setUser)
  }
}
export const signoutUserFromAmplify = async (setUser) => {
  try {
    await Auth.signOut()
    setUser({})
  } catch (error) {
    console.error('signout failed', error.message)
  }
}

export const trimWS = str => typeof str === 'string' ? str.trim() : ''