export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const arraySingleOrEmpty = item => item ? [item] : []

export const isNumeric = str => !isNaN(str) && !isNaN(parseFloat(str))

export const isNullish = val => val == null

export const toDollars = val => Number.isNaN(val) ? '' : Number(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' })

export const dateStringToReadable = (dateString) => {
  // takes a date with format yyyy-mm-dd and converts to m/d/yyyy
  if(!(
    // is string
    (typeof dateString === 'string' || dateString instanceof String)
    // valid input
    && dateString.match(/^\d{4}-\d{2}-\d{2}$/)
    )
  ) return undefined;
  const [y, m, d] = dateString.split('-')
  return `${Number(m)}/${Number(d)}/${Number(y)}`
}

export const getFileExtension = fileName => fileName.toLowerCase().split('.').pop()

export const bytesToMB = bytes => isNumeric(bytes) ? bytes / (1024 * 1024) : 0

export const trimWS = str => typeof str === 'string' ? str.trim() : ''