import { message } from 'antd';
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { Spinner } from '../../shared-modules/general-components/spinner';
import ViewProposal from '../proposals/ProposalPage/view-proposal';
import * as Sentry from "@sentry/react";


export function ProposalPortal({ match, accessKeyEmail, setAccessKeyEmail }) {
  const encodedKey = match.params.key;
  let decodedCreds;
  try {
    decodedCreds = JSON.parse(window.atob(encodedKey))
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    decodedCreds = false
  }
  const { key, email, expires } = decodedCreds ?? {}
  if (process.env.NODE_ENV === 'development') console.log(expires)
  useEffect(() => {
    if (!key) return
    if (!accessKeyEmail?.length) {
      setAccessKeyEmail([key, email])
    }
  }, [accessKeyEmail?.length, email, key, setAccessKeyEmail])
  if (!key) {
    message.error('There was an issue with the link you entered. Please reach out to it@graslawn.com for assistance.')
    return <Redirect push to={'/login'} />
  }
  if (new Date(expires).getTime() < Date.now()) {
    Sentry.captureMessage(`An expired token was rejected. \n EMAIL: ${accessKeyEmail} \n MATCH: ${JSON.stringify(match)}`)
    message.error('This link is expired. Please reach out to the account manager for more information.')
    return <Redirect push to={'/login'} />
  }
  if (!accessKeyEmail?.length) return <Spinner />
  return <ViewProposal vendorAccessKey={true} />
}
