type Fields = {
  addr1: string;
  addr2?: string;
  city: string;
  state: string;
  zip: string;
}

export const addressFromFields = (fields: Fields, newLineSeparated?: boolean) => {
  const separator = newLineSeparated ? '\n' : ', '
  return fields?.addr1 ? `${
    // there must be a addr1
    fields.addr1.trim()
    }${
    // if there is addr2, separate betw 1 & 2
    fields.addr2 ? separator + fields.addr2.trim() : ''
    }${
    // there must be a addr1, if any city/st/zip, separate
    !!(fields.city || fields.state || fields.zip) ? separator : ''
    }${fields.city ? fields.city.trim() : ''
    }${fields.city && fields.state ? ', ' : ''
    }${fields.state ? fields.state.trim() : ''
    }${(fields.city || fields.state) && fields.zip ? ' ' : ''
    }${fields.zip ? ' ' + fields.zip.trim() : ''
    }`.trim()
    : ''
}