import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { ApolloLink } from 'apollo-link';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = ({
  user, setUser, accessKeyEmail, adminCreds, setAdminCreds
}) => {
  const [accessKey, accessEmail] = (accessKeyEmail || []);
  const token = user?.signInUserSession?.idToken?.jwtToken;
  if (adminCreds?.HASURA_SECRET) {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      headers: { 
        'x-hasura-admin-secret': adminCreds.HASURA_SECRET,
        'X-Hasura-User-Id': adminCreds.USER_SUB,
        'X-Hasura-Role': 'vendor'
      },
    })
    return new ApolloClient({
      link: ApolloLink.from([httpLink]),
      cache: new InMemoryCache(),
      defaultOptions
    });

  } else {
    if (token) {
      const httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        headers: { authorization: `Bearer ${token}` },
      })
      return new ApolloClient({
        link: ApolloLink.from([httpLink]),
        cache: new InMemoryCache(),
        defaultOptions
      });
  } else {
    return new ApolloClient({
      link: new HttpLink({	
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        headers: {
          "X-Hasura-Proposal-Key": accessKey,
          "X-Hasura-Proposal-Email": accessEmail
        },
      }),
      cache: new InMemoryCache(),
      defaultOptions
    })
  }
}
} 