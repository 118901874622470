import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, ButtonProps, Popover, PopoverProps, Space } from "antd";
import { FC, useState } from "react";
import color from "shared-modules/helpers/colors";

type Props = {
  onConfirm(): void;
} & Omit<ButtonProps, "onClick"> & Pick<PopoverProps, "content">;

const GrasPopConfirm: FC<Props> = ({
  disabled,
  onConfirm,
  content,
  children,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      visible={open}
      trigger={["click"]}
      onVisibleChange={(newState) => {
        if (!disabled) {
          newState ? setOpen(newState) : setOpen(false);
        }
      }}
      content={
        <Space size="middle">
          <Space size="small">
            <WarningOutlined style={{ color: color("yellow", "xdk") }} />{" "}
            {content ?? "Please Confirm"}
          </Space>
          <Space size="small">
            <Button
              icon={<CheckOutlined />}
              size="small"
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
            />
            <Button
              icon={<CloseOutlined />}
              danger
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </Space>
        </Space>
      }
    >
      <Button
        disabled={disabled}
        {...buttonProps}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Button>
    </Popover>
  );
};

export default GrasPopConfirm;
