import { useContext, useMemo } from "react";
import { UserContext } from "shared-modules/general-hooks/user-context";
import { isAdminUser } from "shared-modules/globals/globals";
import { Pages } from "../../pages";

const useGetUserPages = () => {
  const userData = useContext(UserContext);

  const user = "user" in userData && userData.user;
  const role = user
    ? JSON.parse(
      user.signInUserSession?.idToken?.payload[
      "https://hasura.io/jwt/claims"
      ] ?? "{}"
    )?.["x-hasura-default-role"]
    : "";
  return useMemo(
    () => Pages.filter((page) => isAdminUser ? page.roles.includes(role) : true),
    [role]
  );
};

export default useGetUserPages;
