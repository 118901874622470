// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';


Sentry.init({
  dsn: "https://6acffad563ab476291febcc31ce2d8a9@o529456.ingest.sentry.io/5647807",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(<>
{
    process.env.NODE_ENV === 'development' ? 
      <React.StrictMode>
        <App />
      </React.StrictMode> :
      
      <App />
}
</>,
  document.getElementById('root')
);
