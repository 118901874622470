import { Card, Row, List, Col, Divider, Space, Tag } from 'antd';
import React from 'react';
import { isNullish, toDollars } from 'helpers';
import { PriceInputComponent } from '../components/price-input-component';
import { getNestedValue } from './proposal-helpers';
import color from 'shared-modules/helpers/colors';


export const CardedProposal = ({ proposal, editMap, onEditValueChange, isContract, selectedCard, setSelectedCard, pricingEditable }) => {
  let optionalDividerSet = false;
  const onCardClicked = serviceID => _ => {
    if (selectedCard !== serviceID) setSelectedCard(serviceID)
  }
  const cards = []
  proposal.vendorProposalServices && proposal.vendorProposalServices?.forEach((service, i) => {
    const {
      service_name,
      service_description,
      vendorProposalItems = [],
      vendor_service_price,
      occurrences,
      proposal_service_invoice_type,
      proposal_service_as_needed,
      id: serviceID
    } = service
    const selected = serviceID === selectedCard
    const servicePriceValuePath = [serviceID]
    const servicePriceEditValue = getNestedValue(editMap, servicePriceValuePath)
    let servicePriceText;
    if (!isNullish(servicePriceEditValue)) servicePriceText = <i>{toDollars(servicePriceEditValue)}</i>
    else if (!isNullish(vendor_service_price)) servicePriceText = toDollars(vendor_service_price)
    else if (!pricingEditable) {
      // there should never be a proposal in a stage other than bidding
      // that is missing pricing for a service
      servicePriceText = '';
    }
    else servicePriceText = 'tap to set price'
    if (proposal_service_invoice_type.toLowerCase() === 'optional' && !optionalDividerSet) {
      cards.push(<Divider key='optional-divider' orientation="left"><i>Optional Services</i></Divider>)
      optionalDividerSet = true
    }
    cards.push(<Card 
      // style={style}
      className={`proposal-card${selected ? ' active' : ''}`}
      key={serviceID}
      onClick={onCardClicked(serviceID)}
      >
        <Row>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} >
            <Space>
              <b>{service_name}</b>
              {isContract && !!occurrences && !proposal_service_as_needed && <span><br />Occurences: {occurrences}</span>}
              {proposal_service_as_needed && (<Tag
                style={{
                  textTransform: "uppercase",
                  color: color("green", "dkTag"),
                  borderColor: color("green", "dkTag"),
                  backgroundColor: color("green", "ltTag"),
                }}
              >
                As Needed
              </Tag>
            )}
              {proposal_service_invoice_type.includes("T&M") && (<Tag
                style={{
                  textTransform: "uppercase",
                  color: color("green", "dkTag"),
                  borderColor: color("green", "dkTag"),
                  backgroundColor: color("green", "ltTag"),
                }}
              >
                T&M
              </Tag>
            )}
            </Space>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 6 }}>
            {selected && pricingEditable && <PriceInputComponent
              key={`services${serviceID}`}
              value={servicePriceEditValue !== undefined ?
                servicePriceEditValue : (vendor_service_price ?? '')}
              path={servicePriceValuePath}
              { ...{  onEditValueChange } }
            />}
            { (!selected || !pricingEditable) &&
              servicePriceText}
          </Col>
          
        </Row>
        {selected && <div>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
              <div dangerouslySetInnerHTML={{ __html: service_description }} />
            </Col>
          </Row>
          {!!vendorProposalItems.length && <List
            size="small"
            header={<i><u>Items</u></i>}
            
            dataSource={vendorProposalItems}
            renderItem={item => {
              const {
                item_name, unit_type, vendor_item_price, quantity, id: itemID
              } = item
              const itemPriceValuePath = [serviceID, itemID]
              const itemInputPriceValue = getNestedValue(editMap, itemPriceValuePath)
              const itemCurrentCost = Number(itemInputPriceValue ?? vendor_item_price)
              return <List.Item key={itemID} style={{ margin: '8px 0px' }}>
              <div style={{ width: '100%' }} >
                <Row>
                  <Col xs={{ span: 11 }} md={{ span: 8 }}>
                    {item_name}&nbsp;&mdash;&nbsp;{quantity}&nbsp;*&nbsp;{unit_type}
                  </Col>
                  <Col xs={{ span: 12, offset: 1 }} md={{ span: 12, offset: 0 }}>
                    {pricingEditable && <PriceInputComponent
                      key={`items${itemID}`}
                      defaultValue={`${itemInputPriceValue ?? vendor_item_price ?? ''}`}
                      value={`${itemInputPriceValue ?? vendor_item_price ?? ''}`}
                      path={itemPriceValuePath}
                      { ...{  onEditValueChange } }
                    />}
                    {!pricingEditable && !isNullish(itemCurrentCost) ? toDollars(itemCurrentCost) : ''}
                    {!!(pricingEditable || !isNullish(itemCurrentCost)) && <> per&nbsp;{unit_type} {!!Number(itemCurrentCost) && <i>&mdash;&nbsp;line&nbsp;total&nbsp;${(Number(itemCurrentCost) * quantity).toFixed(2)}</i>}</>}
                  </Col>
                </Row>
              </div>
            </List.Item>}}
          />}
        </div>}
      </Card>) 
    }
  )
  return <div>
    {cards}
  </div>
}