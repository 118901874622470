import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { Space } from "antd";
import { isUndefined } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { AgGridLinkRenderer } from "shared-modules/general-components/ag-grid/helpers";
import StatusTag from "shared-modules/general-components/StatusTag";
import dateComparator, {
  dateStrToMomentUTCDate,
} from "shared-modules/helpers/ag-grid/dateComparator";
import { toCurrency } from "shared-modules/helpers/numbers";
import { isAdminUser, PAGE_LINKS } from "../../globals/globals";
interface ColumnTypeInterface extends ColDef {
  firstFetch?: boolean;
  graphQL: any;
}
export type ColTypes =
  | "vendor_invoice_number"
  | "vendor_invoice_provided_invoice_number"
  | "branch"
  | "invoice_date"
  | "created_at"
  | "amount"
  | "status"
  | "proposal_number"
  | "opportunity"
  | "opsManager"
  | "accountOwner"
  | "property"
  | "aspireReceipt"
  | "vendor";

export default {
  vendor_invoice_number: {
    firstFetch: true,
    headerName: isAdminUser ? "Invoice #" : "Gras Invoice #",
    graphQL: { vendor_invoice_number: true, id: true },
    field: "vendor_invoice_number",
    cellRendererFramework: (params: any) => {
      return params.data ? (
        <Link to={`${PAGE_LINKS.VENDOR_INVOICE_LINK}${params.data.id}`}>
          {params.value}
        </Link>
      ) : (
        <>{params.value}</>
      );
    },
    sortable: true,
  },
  vendor_invoice_provided_invoice_number: {
    firstFetch: true,
    headerName: isAdminUser ? "Provided #" : "Vendor Invoice #",
    graphQL: { provided_invoice_number: true, id: true },
    field: "provided_invoice_number",

    cellRendererFramework: (params: any) =>
      params.data ? (
        <Link to={`${PAGE_LINKS.VENDOR_INVOICE_LINK}${params.data.id}`}>
          {params.value}
        </Link>
      ) : (
        <>{params.value}</>
      ),
    sortable: true,
  },
  proposal_number: {
    firstFetch: true,
    headerName: "Proposal #",
    graphQL: {
      vendorProposal: {
        proposal_number: true,
        id: true,
      },
    },
    field: "vendorProposal.proposal_number",
    cellRendererFramework: (params: ICellRendererParams) => (
      <AgGridLinkRenderer
        linkIdPath="data.vendorProposal.id"
        pageLink="PROPOSAL_LINK"
        params={params}
      />
    ),
    sortable: true,
  },
  branch: {
    firstFetch: true,
    headerName: "Branch",
    graphQL: {
      vendorProposal: {
        opportunity: {
          property: {
            BranchName: true,
          },
        },
      },
    },
    field: "vendorProposal.opportunity.property.BranchName",
    sortable: true,
  },
  opsManager: {
    firstFetch: true,
    headerName: "Ops Manager",
    graphQL: {
      vendorProposal: {
        aspireOpportunity: {
          opsManager: {
            ContactID: true,
            FirstName: true,
            LastName: true,
          },
        },
      },
    },
    valueGetter: ({ data }) => {
      console.log({ data });
      return `${
        data?.vendorProposal?.aspireOpportunity?.opsManager?.FirstName ?? ""
      } ${
        data?.vendorProposal?.aspireOpportunity?.opsManager?.LastName ?? ""
      }`.trim();
    },
    // field: "vendorProposal.aspireOpportunity.opsManager.FullName",
    sortable: true,
  },
  accountOwner: {
    firstFetch: true,
    headerName: "Account Owner",
    graphQL: {
      vendorProposal: {
        aspireOpportunity: {
          property: {
            accountOwner: {
              ContactID: true,
              FirstName: true,
              LastName: true,
            },
          },
        },
      },
    },
    valueGetter: ({ data }) =>
      `${
        data?.vendorProposal?.aspireOpportunity?.property?.accountOwner
          ?.FirstName ?? ""
      } ${
        data?.vendorProposal?.aspireOpportunity?.property?.accountOwner
          ?.LastName ?? ""
      }`.trim(),
    sortable: true,
  },
  opportunity: {
    firstFetch: true,
    headerName: "Opportunity #",
    graphQL: {
      vendorProposal: {
        opportunity: {
          OpportunityNumber: true,
          OpportunityName: true,
          OpportunityID: true,
        },
      },
    },
    valueGetter: ({ data }) =>
      data?.vendorProposal?.opportunity
        ? data.vendorProposal.opportunity.OpportunityNumber +
          " | " +
          data.vendorProposal.opportunity.OpportunityName
        : "",
    cellRendererFramework: (params: ICellRendererParams) => (
      <AgGridLinkRenderer
        linkIdPath="data.vendorProposal.opportunity.OpportunityID"
        pageLink="OPPORTUNITY_LINK"
        params={params}
      />
    ),
    sortable: true,
  },
  property: {
    firstFetch: true,
    headerName: "Property",
    field: "vendorProposal.opportunity.property.PropertyName",
    graphQL: {
      vendorProposal: {
        opportunity: {
          property: {
            PropertyName: true,
            PropertyID: true,
          },
        },
      },
    },
    cellRendererFramework: (params: ICellRendererParams) => (
      <AgGridLinkRenderer
        linkIdPath="data.vendorProposal.opportunity.property.PropertyID"
        pageLink="PROPERTY_LINK"
        params={params}
      />
    ),
    sortable: true,
  },
  vendor: {
    firstFetch: true,
    headerName: "Vendor",
    graphQL: {
      vendorProposal: {
        vendor: {
          id: true,
          name: true,
          company: {
            CompanyName: true,
          },
        },
      },
    },
    valueGetter: ({ data }) =>
      data?.vendorProposal?.vendor?.company?.CompanyName ??
      data?.vendorProposal?.vendor?.name ??
      "",
    cellRendererFramework: (params: ICellRendererParams) => (
      <AgGridLinkRenderer
        linkIdPath="data.vendorProposal.vendor.id"
        pageLink="VENDOR_LINK"
        params={params}
      />
    ),
    sortable: true,
  },
  invoice_date: {
    firstFetch: true,
    headerName: "Date",
    field: "invoice_date",
    graphQL: { invoice_date: true },
    valueGetter: ({ data }) => dateStrToMomentUTCDate(data?.invoice_date),
    valueFormatter: (params) => params?.value?.format("M/D/YYYY"),
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: dateComparator,
      browserDatePicker: true,
    },
    sortable: true,
  },
  created_at: {
    firstFetch: true,
    headerName: "Created",
    field: "created_at",
    graphQL: { created_at: true },
    valueGetter: ({ data }) => dateStrToMomentUTCDate(data?.created_at),
    valueFormatter: (params) => params?.value?.format("M/D/YYYY"),
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: dateComparator,
      browserDatePicker: true,
    },
    sortable: true,
  },
  amount: {
    // firstFetch: true,
    headerName: "$",
    aggFunc: "mySum",
    graphQL: {
      total: {
        __aliasFor: "vendorInvoiceServices_aggregate",
        aggregate: {
          sum: {
            amount: true,
          },
        },
      },
    },
    field: "total.aggregate.sum.amount",
    valueFormatter: (params) =>
      !isUndefined(params.value) ? toCurrency(params.value) : undefined,
    sortable: true,
  },
  status: {
    // firstFetch: true,
    headerName: "Status",
    graphQL: {
      vendorInvoiceCalculated: {
        derived_status: true,
      },
      ...(isAdminUser
        ? {
            errorServices: {
              __aliasFor: "vendorInvoiceServices_aggregate",
              __args: {
                where: {
                  _and: [
                    { is_installment: { _eq: false } },
                    { approved: { _eq: true } },
                    {
                      _and: [
                        {
                          _or: [
                            {
                              calculated: {
                                ticket_date_matches: {
                                  _eq: false,
                                },
                              },
                            },
                            {
                              calculated: {
                                service_matches: {
                                  _eq: false,
                                },
                              },
                            },
                          ],
                        },
                        {
                          override_explanation: {
                            _is_null: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
              aggregate: {
                count: true,
              },
            },
          }
        : {}),
      vendorInvoiceServices: {
        is_installment: true,
      },
    },
    valueGetter: ({ data }) =>
      [
        data?.vendorInvoiceCalculated?.derived_status ?? "",
        data?.vendorInvoiceServices?.[0]?.is_installment ? "INSTALLMENT" : "",
        data?.errorServices?.aggregate?.count ? "ERROR" : "",
      ]
        .filter((str) => str)
        .join(","),

    cellRendererFramework: (params: any) => (
      <Space>
        {params.value
          ?.split(",")
          ?.map(
            (status: React.ComponentProps<typeof StatusTag>["status"]) =>
              status && (
                <StatusTag
                  status={
                    !isAdminUser && status === "APPROVED" ? "REVIEWED" : status
                  }
                />
              )
          )}
      </Space>
    ),

    sortable: true,
  },
  aspireReceipt: {
    firstFetch: true,
    headerName: "Aspire Receipt",
    graphQL: {
      purchaseReceipt: { ReceiptID: true, ReceiptNumber: true },
    },
    sortable: true,
    field: "purchaseReceipt.ReceiptNumber",
    cellRendererFramework: (params: ICellRendererParams) => (
      <AgGridLinkRenderer
        customPath={
          params.data?.purchaseReceipt?.ReceiptID
            ? process.env.REACT_APP_ASPIRE_BASE_URL +
              "/purchasing/receipts/details/" +
              params.data.purchaseReceipt.ReceiptID
            : undefined
        }
        params={params}
        externalLink={true}
        target={"_blank"}
      />
    ),
  },
} as Record<ColTypes, ColumnTypeInterface>;
