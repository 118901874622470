import { Button, ButtonProps, Tag } from "antd";
import { FC, ReactElement } from "react";
import color from "shared-modules/helpers/colors";
import { ReactFCC } from "shared-modules/shared-types";

type AltComponent = { component: ReactElement; toggle: boolean };

type TagSize = "lg" | "sm";

export type StatusTagObjType = {
  content: string | number | ReactElement;
  icon?: ReactElement;
  mainColor?: string;
  bgColor?: string;
  buttonText?: ReactElement;
  buttonProps?: ButtonProps;
  altComponent?: AltComponent;
  isUppercase?: boolean;
};

export type HeaderTagsPropTypes = {
  statusTags: StatusTagObjType[];
  size?: TagSize;
};

type HeaderTagType = {
  key: any;
  icon?: ReactElement;
  mainColor?: string;
  bgColor?: string;
  buttonText?: ReactElement;
  buttonProps?: ButtonProps;
  altComponent?: AltComponent;
  isUppercase?: boolean;
  size?: TagSize;
};

type HeaderTagIconProps = {
  element: ReactElement;
};

const HeaderTagIcon: ReactFCC<HeaderTagIconProps> = ({ element }) => (
  <div style={{ marginRight: "5px", display: "flex", alignItems: "center" }}>
    {element}
  </div>
);

// const HeaderTagButton: FC<HeaderTagButtonProps> = ({}) => {};

export const HeaderTag: ReactFCC<HeaderTagType> = ({
  children,
  icon,
  mainColor,
  bgColor,
  buttonText,
  buttonProps,
  altComponent,
  isUppercase = true,
  size = "lg",
}) => {
  // console.log({ mainColor, bgColor });
  return (
    <div style={{ display: "flex" }}>
      {!altComponent?.toggle ? (
        <>
          <Tag
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: size === "lg" ? "14px" : "11px",
              textTransform: isUppercase ? "uppercase" : "none",
              fontWeight: 500,
              letterSpacing: "0.15em",
              padding: size === "lg" ? "3px 6px 3px 8px" : "2px 4px 2px 6px",
              border: `1px solid ${
                mainColor ? mainColor : color("grey", "xxdk")
              }`,
              backgroundColor: bgColor ? bgColor : color("grey", "xlt"),
              color: mainColor ? mainColor : color("grey", "xxdk"),
            }}
          >
            {icon && <HeaderTagIcon element={icon} />} {children}
          </Tag>
          {(buttonText || buttonProps) && (
            <Button
              style={{
                borderColor: mainColor ? mainColor : color("green", "xlt"),
                color: color("green"),
                marginLeft: "-6px",
              }}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          )}
        </>
      ) : (
        altComponent.component
      )}
    </div>
  );
};

const PageStatusTags: FC<HeaderTagsPropTypes> = ({
  statusTags,
  size = "lg",
}) => {
  const statusTagsList =
    statusTags && statusTags.length > 0
      ? statusTags.map((tag) => (
          <HeaderTag
            icon={tag.icon}
            mainColor={tag.mainColor}
            bgColor={tag.bgColor}
            buttonText={tag.buttonText}
            buttonProps={tag.buttonProps}
            key={String(tag.content)}
            altComponent={tag.altComponent}
            isUppercase={tag.isUppercase}
            size={size}
          >
            {tag.content}
          </HeaderTag>
        ))
      : null;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginBottom: -3,
      }}
    >
      {statusTagsList}
    </div>
  );
};

export default PageStatusTags;
