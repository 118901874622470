import { gql } from "@apollo/client";

export const updateProposalPricingMutation = gql`
  mutation MyMutation($servicesToUpdate: [vendorProposalService_insert_input!]!, , $itemsToUpdate: [vendorProposalItem_insert_input!]!) {
    insert_vendorProposalService(objects: $servicesToUpdate, on_conflict: {constraint: vendorProposalService_pkey, update_columns: vendor_service_price}) {
      affected_rows
    }
    insert_vendorProposalItem(objects: $itemsToUpdate, on_conflict: {constraint: vendorProposalItem_pkey, update_columns: vendor_item_price}) {
      affected_rows
    }
  }
`

export const updateProposalStatusMutation = gql`
  mutation MyMutation($id: uuid!) {
    update_vendorProposals_by_pk(pk_columns: {id: $id}, _set: {status: "SUBMITTED"}) {
      id
    }
  }
`