import { gql, useMutation } from "@apollo/client";

const SET_VIEWED_MUTATION = gql`
  mutation MyMutation($data: String!) {
    proposalViewed(data: $data) {
      data
      error
    }
  }
`
export const useSetProposalViewed = () => {
  const [setProposalViewed] = useMutation(SET_VIEWED_MUTATION);

  return { setProposalViewed };
}