import { Auth } from "aws-amplify";
import { AppContext } from "context/app-context";
import { useContext } from "react";
import { UserContext } from "shared-modules/general-hooks/user-context";


const useLogout = () => {
  const { setAdminCreds, adminCreds } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const logout = () => adminCreds && setAdminCreds ? setAdminCreds(undefined) : Auth.signOut().then(_ => ('setUser' in userContext) && userContext.setUser({}))
  return [logout]
}

export default useLogout
