import React, { FC } from "react";
import { Button, Modal } from "antd";
import { PAGE_LINKS } from "shared-modules/globals/globals";
import { useHistory } from "react-router-dom";
import VendorInvoiceList from "pages/vendor-invoices/VendorInvoiceList";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import GrasPopConfirm from "shared-modules/general-components/GrasPopConfirm";
dayjs.extend(relativeTime);


type Props = {
  visible: boolean;
  setVisible(visible: boolean): void;
  proposalID: string;
  proposal_end_date: string;
  status: string;
};
const InvoicesModal: FC<Props> = ({ visible, setVisible, proposalID, proposal_end_date, status }) => {
  const newInvoiceURL = `${PAGE_LINKS.VENDOR_INVOICE_LINK}?proposalID=${proposalID}`;
  const expiredSince =  dayjs(proposal_end_date).isBefore(dayjs(), "date") ? dayjs(proposal_end_date).fromNow() : "";

  const history = useHistory();  
  const closeModal = () => setVisible(false);
  const onNewInvoice: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    history.push(newInvoiceURL);
  };
  return (
    <Modal
      visible={visible}
      width={window.innerWidth * 0.8}
      title="View Invoices"
      onCancel={closeModal}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 8
          }}
        >
          <Button danger onClick={closeModal}>
            Close
          </Button>
          {expiredSince 
            ? (<GrasPopConfirm
              content={
                <div>
                  <div>
                    This contract has ended {expiredSince}.
                  </div>
                  <div>
                    Invoices submitted against the wrong contract cannot be accepted.
                  </div>
                  <div>
                    Create invoice anyway?
                  </div>
                </div>
              }
              onConfirm={() => history.push(newInvoiceURL)}
            >
              <Button
                type="link"
              >
                Create New Invoice
              </Button>
            </GrasPopConfirm>)
            : (<Button href={newInvoiceURL} onClick={onNewInvoice} type="primary">
              Create New Invoice
            </Button>)}
        </div>
      }
    >
      <div style={{ height: window.innerHeight * 0.5 }}>
        <VendorInvoiceList
          tableKey="proposal-page"
          cols={[
            "vendor_invoice_provided_invoice_number",
            "vendor_invoice_number",
            "invoice_date",
            "amount",
            "status",
          ]}
          where={{
            vendorProposal: {
              id: {
                _eq: proposalID,
              },
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default InvoicesModal;
