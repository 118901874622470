import { createContext } from "react";

type User = {

}
interface FilterSortModels {
  [key: string]: any 
}
interface Sider {
  siderOpened: boolean
  setSiderOpened: React.Dispatch<React.SetStateAction<boolean>>
}
type AdminCreds = {
  HASURA_SECRET: string;
  USER_SUB: string;
}
type AppContextType = {
  user?: User;
  sider?: Sider;
  setUser?: Function;
  adminCreds?: AdminCreds; 
  setAdminCreds?: Function; 
  filterModels?: FilterSortModels,
  sortModels?: FilterSortModels,
}

export const AppContext = createContext<AppContextType>({})
