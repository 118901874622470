import { gql } from '@apollo/client'
import { message } from 'antd'
import { RowNode } from "@ag-grid-community/core";

import useMutationWithFeedback from 'shared-modules/general-hooks/useMutationWithFeedback'
import set from 'lodash/set';
import clone from 'lodash/clone';
const MUTATION = gql`
mutation MyMutation($object: vendor_service_record_schedule_insert_input!) {
  insert_vendor_service_record_schedule_one(object: $object) {
    vendorServiceRecord {
      schedules(order_by: {schedule_date: asc_nulls_last}) {
        id
        completed
        schedule_date
      }
    }
  }
}

`

const useAddRecordSchedule = (rowData: any, rowNode: RowNode) => {
  const { doAction, loading, error, data } = useMutationWithFeedback({
    mutation: MUTATION,
    onError: (error) => {
      console.error("useAddRecordSchedule ERROR:", error)
      message.error({ key: "useAddRecordSchedule", content: "An error occurred. Please try again.", duration: 2.5 })
    },
    onSuccess: (successData) => {
      message.success({ key: "useAddRecordSchedule", content: "Saved!", duration: 2.5 })
      rowNode.setData(set(clone(rowData), "record.schedules", successData.insert_vendor_service_record_schedule_one.vendorServiceRecord.schedules))
    },
    onLoading: () => {
      message.loading({ key: "useAddRecordSchedule", content: "Saving...", duration: 0 })
    }
  })
  return { doAction, loading, error, data }
}

export default useAddRecordSchedule