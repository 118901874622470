import { lazy } from "react";
import AllActiveProposalServicesPage from "shared-modules/all-active-proposal-services/AllActiveProposalServicesPage";
import { PAGE_LINKS } from "shared-modules/globals/globals";
import ViewAllInvoices from "shared-modules/vendor_invoices/ViewAllInvoices";
// import ScheduleBoard from "./pages/schedule/schedule-board/ScheduleBoard.js";

const ViewAllProposals = lazy(() =>
  import("./pages/proposals/view-all-proposals")
);
const ViewProposal = lazy(() =>
  import("./pages/proposals/ProposalPage/view-proposal")
);
const ScheduleBoard = lazy(() =>
  import("pages/schedule/schedule-board/schedule-board2/ScheduleBoard")
);
// const WorkTickets = lazy(() =>
//   import("pages/schedule/work-tickets/WorkTickets")
// );
const VendorInvoiceWrapper = lazy(() =>
  import("shared-modules/vendor_invoices/view-invoice/VendorInvoiceWrapper")
);

export const Pages = [
  {
    component: ViewAllProposals,
    linkText: "Proposals",
    path: "/view-proposals",
    roles: ["vendor"],
  },
  {
    component: ViewAllInvoices,
    linkText: "Invoices",
    path: "/view-invoices",
    roles: ["vendor"],
  },
  {
    component: AllActiveProposalServicesPage,
    linkText: "Contracted Services",
    path: "/all-contracted-services",
    roles: ["vendor"],
  },
  {
      component: ScheduleBoard,
      linkText: 'Schedule Board',
      path: '/schedule-board',
      roles: ['vendor'],
  },
  {
    component: ViewProposal,
    path: PAGE_LINKS.PROPOSAL_LINK + ":id",
    roles: ["vendor"],
  },
  //   {
  //       component: WorkTickets,
  //       linkText: 'Work Tickets',
  //       path: '/work-tickets',
  //       roles: ['vendor'],
  //   },
  {
    component: VendorInvoiceWrapper,
    path: PAGE_LINKS.VENDOR_INVOICE_LINK,
    roles: ["admin", "vendor_mgmt"],
    title: "Vendor Invoice",
  },
  {
    component: VendorInvoiceWrapper,
    path: PAGE_LINKS.VENDOR_INVOICE_LINK + ":id",
    roles: ["admin", "vendor_mgmt"],
    title: "Vendor Invoice",
  },
];
