import React from "react";
import {
  Button,
  Col,
  Typography,
  Row,
  Space,
  Dropdown,
  Grid,
  Menu,
} from "antd";
import { LogoutOutlined, MenuOutlined, MoreOutlined } from "@ant-design/icons";
import PageStatusTags from "./PageStatusTags";
import useLogout from "helpers/useLogout";
const { useBreakpoint } = Grid;

export const MainHeader = (props) => {
  const { sm, md, xl, lg } = useBreakpoint();
  const menuItems = [];
  const [logout] = useLogout();
  if (props.menuItems) menuItems.push(...props.menuItems);
  menuItems.push({
    key: "logout",
    title: "Sign Out",
    icon: <LogoutOutlined />,
    onClick: () => logout(),
  });

  // return <PageHeader
  //   className="site-page-header"
  //   { ...rest }

  //   extra={[
  //     ...extra.map((el, i) => ({ ...el, i})),
  //     <Button key='logout' onClick={() => Auth.signOut().then(_ => setUser({}))}>Sign Out</Button>
  //   ]}
  // />}

  return (
    <Row align="middle" style={{ padding: "16px 24px", fontSize: "16px" }}>
      <Col
        xs={{ span: 23 }}
        md={{ span: 20 }}
        xl={{ span: 16 }}
        style={{ display: "flex" }}
      >
        <Space style={{ display: "flex", alignItems: "center" }}>
          {props.backIcon || <MenuOutlined onClick={props.toggleSider} />}
          <img
            src="/img/logoG192.svg"
            alt="Logo"
            className="logo"
            style={{
              maxWidth: "100%",
              maxHeight: "45px",
              marginLeft: md ? "15px" : "0px",
              marginRight: "15px",
              visibility: props.siderOpened ? "hidden" : "visible",
              display: props.siderOpened ? "none" : "block",
              objectFit: "contain",
            }}
          />
          {typeof props.title === "string" ? (
            lg ? (
              <Space align="end">
                <Typography.Title
                  level={md ? 3 : 5}
                  style={{ margin: 0, lineHeight: 1 }}
                >
                  {props.title}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {props.subTitle}
                </Typography.Text>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                  }}
                >
                  {Array.isArray(props.statusTags) ? (
                    <PageStatusTags statusTags={props.statusTags} />
                  ) : (
                    props.statusTags
                  )}
                </div>
              </Space>
            ) : (
              <div style={{}}>
                <Row>
                  <Col span={24}>
                    <Typography.Title
                      level={md ? 3 : 5}
                      style={{ margin: 0, lineHeight: 1 }}
                    >
                      {props.title}
                    </Typography.Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Typography.Text type="secondary">
                      {props.subTitle}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            )
          ) : (
            props.title
          )}
        </Space>
      </Col>
      {!!menuItems?.length && (!props.siderOpened || sm) && (
        <Col xs={{ span: 1 }} md={{ span: 4 }} xl={{ span: 8 }}>
          <Row justify="end">
            {xl ? (
              <Space>
                {menuItems.map((Item, i) => {
                  return typeof Item === "function" ? (
                    <Item key={"component-menu-item-" + i} />
                  ) : (
                    <Button
                      type={Item.buttonType ?? "default"}
                      key={Item.key}
                      disabled={Item.disabled}
                      icon={Item.icon}
                      onClick={Item.onClick}
                    >
                      {Item.title}
                    </Button>
                  );
                })}
              </Space>
            ) : (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {menuItems.map((Item) => (
                      <Menu.Item
                        key={Item.key}
                        disabled={props.disabled}
                        icon={Item.icon}
                        onClick={Item.onClick}
                      >
                        {typeof Item === "function" ? (
                          <Item buttonType="text" />
                        ) : (
                          Item.title
                        )}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                {md ? <Button>Options</Button> : <MoreOutlined />}
              </Dropdown>
            )}
          </Row>
        </Col>
      )}
    </Row>
  );
};
