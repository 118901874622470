import React from 'react'
import { Card, Spin } from 'antd'

export const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-inner">
        <Spin>
          <Card className="spinner-card">
          
          </Card>
        </Spin>      

      </div>
    </div>
  )
}
