import { Button } from "antd";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import GrasAgGrid from "shared-modules/general-components/ag-grid/GrasAgGrid";
import colDefs, { ColTypes } from "./colDefs";
import useGetInvoices from "./useGetInvoices";

type PropTypes = {
  cols: ColTypes[];
  tableKey: string;
  where?: object;
};
const InvoiceList: FC<PropTypes> = ({ cols, tableKey, where }) => {
  const { data, error, loading } = useGetInvoices(cols, where);
  const history = useHistory();

  if (error)
    return (
      <div>
        An error occured{" "}
        <Button type="link" onClick={() => history.push("/")}>
          Go back home
        </Button>
      </div>
    );
  return (
    <div style={{ height: "100%" }}>
      <GrasAgGrid
        dataLoading={!!loading}
        rowData={data?.invoices ?? []}
        tableKey={tableKey}
        columnDefs={cols.map((col) => {
          const { graphQL, ...rest } = colDefs[col];
          return rest;
        })}
      />
    </div>
  );
};

export default InvoiceList;
