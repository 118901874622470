import { Button, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { AppContext } from "context/app-context";
import React, { ChangeEvent, FC, useContext, useState } from "react";
type Props = {
  destroyModal: Function;
};
type State = {
  adminSecret: string;
  userID: string;
};
const AdminLoginModal: FC<Props> = (props) => {
  const appContext = useContext(AppContext);

  const [state, setState] = useState<State>({
    adminSecret: "",
    userID: "",
  });
  const HASURA_SECRET =
    process.env?.REACT_APP_ADMIN_SECRET ?? state.adminSecret;
  const USER_SUB = process.env?.REACT_APP_USER_ID ?? state.userID;
  const onChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState((oldState) => ({
      ...oldState,
      [field]: value,
    }));
  };
  const login = () =>
    appContext.setAdminCreds &&
    appContext.setAdminCreds({
      HASURA_SECRET: state.adminSecret,
      USER_SUB: state.userID,
    });
  return (
    <Modal
      visible
      title="Admin Login"
      footer={[
        <Button disabled={!HASURA_SECRET || !USER_SUB} onClick={login}>
          Login
        </Button>,
        <Button onClick={() => props.destroyModal()}>Cancel</Button>,
      ]}
    >
      <Input
        placeholder="Admin Secret"
        value={state.adminSecret}
        onChange={onChange("adminSecret")}
      />
      <Input
        placeholder="User ID"
        value={state.userID}
        onChange={onChange("userID")}
      />
    </Modal>
  );
};
export default AdminLoginModal;
