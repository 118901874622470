import React from "react";
import color from "shared-modules/helpers/colors";

const SubTitleDivider = () => {
  return (
    <span
      style={{
        fontSize: 9,
        marginRight: 7,
        marginLeft: 7,
        color: color("grey", "lt"),
      }}
    >
      |
    </span>
  );
};

export default SubTitleDivider;
