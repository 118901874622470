import React from 'react';
import { Input } from "antd";

export const PriceInputComponent = ({ onEditValueChange, addonAfter, path, ...rest }) => <Input
  style={{ width: '150px' }}
  addonBefore='$'
  addonAfter={addonAfter}
  // value={editMap ? editMap?.[rowType]?.[row.id]?.[field] || row[field] : row[field]} 
  onChange={onEditValueChange(path, 'currency')}
  onBlur={onEditValueChange(path, 'currency-blur')}
  { ...rest }
/>