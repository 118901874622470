import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  notification,
  Spin,
  Typography,
} from "antd";
import { Auth } from "aws-amplify";
import AdminLoginModal from "./AdminLoginModal";

const LoginComponent = ({ setUser }) => {
  const [localUser, setLocalUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [adminLogin, setAdminLogin] = useState(false);
  useEffect(() => {
    const KeyPress = (e) =>
      e.keyCode === 81 && e.ctrlKey && e.shiftKey && setAdminLogin(true);
    document.addEventListener("keyup", KeyPress);
    return () => {
      document.removeEventListener("keyup", KeyPress);
      message.destroy("login-screen")
    };
  }, []);
  const onFinish = async ({
    username,
    password,
    newPassword,
    confirmNewPassword,
    // code,
  }) => {
    setIsLoading(true);
    try {
      let userResult;
      // if (resetPasswordMode) {
      //   userResult = await Auth.forgotPasswordSubmit(username.trim(), code, password);
      //   message.info({
      //     key: "login-screen",
      //     content: "Password Reset. Please login."
      //   });

      //   setResetPasswordMode(false);
      //   setIsLoading(false);
      // } else 
      if (localUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (newPassword !== confirmNewPassword)
          return alert("passwords do not match");
        userResult = await Auth.completeNewPassword(localUser, newPassword);
      } else {
        userResult = await Auth.signIn(username.trim(), password);
      }
      if (userResult?.signInUserSession) {
        if (process.env.NODE_ENV === "development") {
          console.log("Success");
          console.log(userResult);
        }
        const userResponse = await Auth.currentAuthenticatedUser();
        setUser(userResponse);
      } else if (userResult?.challengeName === "NEW_PASSWORD_REQUIRED") {
        setLocalUser(userResult);
        setIsLoading(false);
      }
    } catch (error) {
      // if (error.code === "PasswordResetRequiredException") {
      //   setResetPasswordMode(true);
      // }
      console.log("error signing in", error);
      notification.error({
        description: error.message,
        key: "login-screen",
        message: "Signin Error",
        placement: "bottomRight",
      });
      setIsLoading(false);
    }
  };
  const onFinishFailed = console.log;
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundImage: `linear-gradient(
          to right,
          rgba(156, 156, 156, 0.8),
          rgba(28, 255, 179, 0.849)),
          url(../img/gazeebo.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="logo-box">
        <img src="/img/logo-white.png" alt="Logo" className="logo" />
      </div>
      <div className="login-container">
        {adminLogin && (
          <AdminLoginModal destroyModal={() => setAdminLogin(false)} />
        )}
        {/* {resetPasswordMode && (
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Typography.Title level={3}>Reset Password</Typography.Title>
            <Spin spinning={isLoading}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Confirmation Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your confirmation code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
              </Form.Item> 

              <Form.Item>
                <Button
                  disabled={isLoading}
                  style={{ marginRight: "5px" }}
                  type="outlined"
                  // onClick={() => setResetPasswordMode(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  style={{ marginRight: "5px" }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        )} */}

        {!localUser?.challengeName && (
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Typography.Title level={3}>Vendor Login</Typography.Title>
            <Spin spinning={isLoading}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

              <Form.Item>
                <Button
                  disabled={isLoading}
                  style={{ marginRight: "5px" }}
                  type="text"
                  onClick={() => {
                    message.info({
                      key: "login-screen",
                      content: 
                        "Please reach out to support to reset your password. If you received a temporary password, us it to log in below.",
                      duration: 10,
                    }
                    );
                  }}
                >
                  Reset Password
                </Button>

                <Button
                  disabled={isLoading}
                  style={{ marginRight: "5px" }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        )}

        {localUser?.challengeName && (
          <Form
            name="challenge"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Typography.Title level={3}>Set new Password</Typography.Title>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                disabled={isLoading}
                style={{ marginRight: "5px" }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export const Login = LoginComponent;
