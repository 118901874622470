import { ICellRendererParams } from "@ag-grid-community/core";
import { message, Switch } from "antd";
import { FC } from "react";
import useRemoveSeviceRecord from "../useRemoveServiceRecord";
import useSaveSeviceRecord from "../useSaveSeviceRecord";

const SaveRecordCheckbox: FC<{ params: ICellRendererParams }> = ({
  params,
}) => {
  const { doAction: doSave, loading: saveLoading } = useSaveSeviceRecord(
    params.data,
    params.node
  );
  const { doAction: doRemove, loading: removeLoading } = useRemoveSeviceRecord(
    params.data,
    params.node
  );
  const { value } = params;
  const hasSchedules = !!params.data?.record?.schedules?.length;
  return (
    <Switch
      checkedChildren={value}
      unCheckedChildren={value}
      disabled={saveLoading || removeLoading}
      checked={value === "Yes"}
      onClick={() => {
        if (hasSchedules)
          message.error({
            key: "SaveRecordCheckbox",
            content: "Cannot remove record with scheduled visits.",
          });
        else value === "No" ? doSave() : doRemove();
      }}
    />
  );
};

export default SaveRecordCheckbox;
