import Auth from "@aws-amplify/auth";
import { ApolloProvider } from "@apollo/client";
import React, {
  createElement,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { client } from "./apollo-client";
import { ModuleRegistry } from "@ag-grid-community/core"; // @ag-grid-community/core will always be implicitly available
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
// ag grid styles
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import "./shared-modules/sass/App.scss";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Login } from "./components/login";
import { AppContext } from "./context/app-context";
import { MainMenu } from "./components/main-menu";
import { Pages } from "./pages";
import { Spinner } from "./shared-modules/general-components/spinner";
import { ProposalPortal } from "./pages/proposal-with-access-key/ProposalPortal";
import { UserContext } from "shared-modules/general-hooks/user-context";
import UserExpiresModal from "shared-modules/general-components/UserExpiresModal";
import ModalUnmountOnPathChange from "./ModalUnmountOnPathChange";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

Amplify.configure(awsconfig);

const HASURA_SECRET = process.env?.REACT_APP_ADMIN_SECRET;
const USER_SUB = process.env?.REACT_APP_USER_ID;

function App() {
  const [user, setUser] = useState(undefined);
  const [accessKeyEmail, setAccessKeyEmail] = useState([]);
  const [adminCreds, setAdminCreds] = useState({
    HASURA_SECRET,
    USER_SUB,
  });
  const [siderOpened, setSiderOpened] = useState(false);
  const siderCallback = useCallback(setSiderOpened, [setSiderOpened]);

  console.log(process.env.NODE_ENV);
  useEffect(() => {
    (async () => {
      try {
        if (!user) setUser(await Auth.currentAuthenticatedUser());
      } catch (error) {
        setUser({});
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (process.env.NODE_ENV === "development") console.log(user);
  // must return early or undefined user will redirect
  if (!user) return <Spinner />;
  return (
    <>
      <UserExpiresModal user={user} setUser={setUser} />
      <AppContext.Provider
        value={{
          adminCreds,
          setAdminCreds,
          sider: { siderOpened, setSiderOpened: siderCallback },
        }}
      >
        <UserContext.Provider value={{ user, setUser }}>
          <ApolloProvider
            client={client({
              user,
              setUser,
              accessKeyEmail,
              adminCreds,
              setAdminCreds,
            })}
          >
            {" "}
            {/* client(user) */}
            <Router>
              <Route
                key={"ModalUnmountOnPathChange"}
                component={ModalUnmountOnPathChange}
              />
              <Switch>
                {(!!user?.signInUserSession || !!adminCreds?.HASURA_SECRET) &&
                  // <MainMenu>
                  //   {Pages.map(({path, title, component}) => <Route exact path={path} key={path} render={props => createElement(component, { ...props, title })} />)}
                  // </MainMenu>

                  Pages.map((page) => (
                    <Route
                      exact
                      path={page.path}
                      key={page.path}
                      render={(props) => (
                        <MainMenu
                          {...props}
                          selectedPath={page.path}
                          links={Pages}
                        >
                          <Suspense fallback={<Spinner />}>
                            {React.createElement(page.component, props)}
                          </Suspense>
                        </MainMenu>
                      )}
                    />
                  ))}
                {(!!user?.signInUserSession || !!adminCreds?.HASURA_SECRET) && (
                  <Route
                    path="/"
                    render={() => <Redirect to="/view-proposals" />}
                  />
                )}
                {/* {user?.signInUserSession && <Redirect  to='/view-proposals' />} */}
                {!user?.signInUserSession && !adminCreds?.HASURA_SECRET && (
                  <>
                    <Switch>
                      <Route
                        exact
                        path="/proposal-portal/:key"
                        render={(props) => (
                          <ProposalPortal
                            {...props}
                            {...{ accessKeyEmail, setAccessKeyEmail }}
                          />
                        )}
                      />

                      <Route
                        exact
                        path={"/login"}
                        render={(props) =>
                          createElement(Login, { ...props, setUser })
                        }
                      />
                      <Route path="/" render={() => <Redirect to="/login" />} />
                    </Switch>
                  </>
                )}
              </Switch>
            </Router>
          </ApolloProvider>
        </UserContext.Provider>
      </AppContext.Provider>
    </>
  );
}

export default App;
