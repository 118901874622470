import { ApolloError, MutationHookOptions, useMutation, DocumentNode } from "@apollo/client"
import { useEffect } from "react";

type FeedBackMutationParams<T, V> = MutationHookOptions<T, V> & {
  mutation: DocumentNode
  onSuccess: (value: T | null | undefined) => any
  onError: (error: ApolloError | undefined) => any
  onLoading: () => any
}
const useMutationWithFeedback = <T = any, V = any>(params: FeedBackMutationParams<T, V>) => {
  const { onError, onLoading, onSuccess, mutation, ...restParams } = params
  const [doAction, state] = useMutation<T, V>(mutation, restParams)
  const { error, data, loading } = state
  const hasData = !!data
  const hasError = !!error
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { hasError && onError(error) }, [hasError])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { hasData && onSuccess(data) }, [hasData])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loading && onLoading() }, [loading])
  return {
    ...state,
    doAction
  }
}

export default useMutationWithFeedback