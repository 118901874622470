import { RowNode } from "@ag-grid-community/core";
import { gql } from '@apollo/client';
import { message } from 'antd';
import useMutationWithFeedback from 'shared-modules/general-hooks/useMutationWithFeedback';

const MUTATION = gql`
  mutation MyMutation($object: vendor_service_record_insert_input!) {
    insert_vendor_service_record_one(object: $object) {
      id
      property_name_abr
      proposal_end_date
      proposal_start_date
      proposal_number
      vendor_service_price
      schedules(order_by: {schedule_date: asc_nulls_last}) {
        id
        completed
        schedule_date
      }        
    }
  }
`

const useSaveSeviceRecord = (rowData: any, rowNode: RowNode) => {
  const { doAction, loading, error, data } = useMutationWithFeedback({
    mutation: MUTATION,
    variables: {
      object: {
        id: rowData.id,
        proposal_service_id: rowData.proposal_service_id,
        service_name: rowData.service_name,
        property_name_abr: rowData?.property?.PropertyNameAbr,
        proposal_start_date: rowData.proposal_start_date,
        proposal_end_date: rowData.proposal_end_date,
        proposal_number: rowData.proposal_number,
        vendor_service_price: rowData.vendor_service_price,
        vendor_id: rowData.vendor_id,
      }
    },
    onError: (error) => {
      console.error("useSaveSeviceRecord ERROR:", error)
      message.error({ key: "useSaveSeviceRecord", content: "An error occurred. Please try again.", duration: 2.5 })
    },
    onSuccess: (successData) => {
      message.success({ key: "useSaveSeviceRecord", content: "Saved!", duration: 2.5 })
      rowNode.setData({
        ...rowData,
        record: successData.insert_vendor_service_record_one,
        isSaved: true
      })
    },
    onLoading: () => {
      message.loading({ key: "useSaveSeviceRecord", content: "Saving...", duration: 0 })
    }
  })
  return { doAction, loading, error, data }
}

export default useSaveSeviceRecord