import { gql } from '@apollo/client'
import { message } from 'antd'
import { RowNode } from "@ag-grid-community/core";

import useMutationWithFeedback from 'shared-modules/general-hooks/useMutationWithFeedback'
import { clone, set } from 'lodash';
const MUTATION = gql`
mutation MyMutation($id: uuid!, $completed: Boolean!) {
  update_vendor_service_record_schedule_by_pk(_set: {completed: $completed}, pk_columns: {id: $id}) {
    vendorServiceRecord {
      schedules(order_by: {schedule_date: asc_nulls_last}) {
        id
        completed
        schedule_date
      }
    }
  }
}
`

const useUpdateRecordSchedule = (rowData: any, rowNode: RowNode) => {
  const { doAction, loading, error, data } = useMutationWithFeedback({
    mutation: MUTATION,
    onError: (error) => {
      console.error("useUpdateRecordSchedule ERROR:", error)
      message.error({ key: "useUpdateRecordSchedule", content: "An error occurred. Please try again.", duration: 2.5 })
    },
    onSuccess: (successData) => {
      message.success({ key: "useUpdateRecordSchedule", content: "Saved!", duration: 2.5 })
      rowNode.setData(set(clone(rowData), "record.schedules", successData.update_vendor_service_record_schedule_by_pk.vendorServiceRecord.schedules))
    },
    onLoading: () => {
      message.loading({ key: "useUpdateRecordSchedule", content: "Saving...", duration: 0 })
    }
  })
  return { doAction, loading, error, data }
}

export default useUpdateRecordSchedule