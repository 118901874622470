import { gql } from '@apollo/client'
import { message } from 'antd'
import { RowNode } from "@ag-grid-community/core";

import useMutationWithFeedback from 'shared-modules/general-hooks/useMutationWithFeedback'
import { omit } from 'lodash';
const MUTATION = gql`
  mutation MyMutation($id: String!) {
    delete_vendor_service_record_by_pk(id: $id) {
      id
    }
  }
`

const useRemoveSeviceRecord = (rowData: any, rowNode: RowNode) => {
  const { doAction, loading, error, data } = useMutationWithFeedback({
    mutation: MUTATION,
    variables: {
      id: rowData.id,
    },
    onError: (error) => {
      console.error("useRemoveSeviceRecord ERROR:", error)
      message.error({ key: "useRemoveSeviceRecord", content: "An error occurred. Please try again.", duration: 2.5 })
    },
    onSuccess: (successData) => {
      process.env.NODE_ENV === "development" && console.log({ successData })
      message.success({ key: "useRemoveSeviceRecord", content: "Saved!", duration: 2.5 })
      rowNode.setData({
        ...omit(rowData, "record"),
        isSaved: false//(rowData?.vendorServiceRecords ?? []).filter((record: any) => record.id !== rowData.id)
      })
    },
    onLoading: () => {
      message.loading({ key: "useRemoveSeviceRecord", content: "Saving...", duration: 0 })
    }
  })
  return { doAction, loading, error, data }
}

export default useRemoveSeviceRecord