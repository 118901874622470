import chroma from "chroma-js";
import values from "lodash/values";

// BASE COLORS (RGB VALUES)
const baseNavy = [0, 52, 77];
const baseGreen = [138, 198, 64];
const baseGreenAlt = [100, 169, 68];
const baseBlue = [78, 201, 245];
const baseOrange = [255, 91, 60];
const baseYellow = [247, 245, 58];
const baseRed = [223, 39, 51];
const baseGrey = chroma(baseGreen).desaturate(3.75).brighten(0.25).rgb();

// TONES
const colorObj = {
  navy: {
    base: baseNavy,
  },
  green: {
    base: baseGreen,
    lt: chroma(baseGreen).brighten(1).rgb(),
    xlt: chroma(baseGreen).brighten(2).rgb(),
    dk: chroma(baseGreen).darken(1).rgb(),
    xdk: chroma(baseGreen).darken(2).rgb(),
    alt: baseGreenAlt,
  },
  blue: {
    base: baseBlue,
    dk: chroma(baseBlue).darken(1).rgb(),
    dstd: chroma(baseBlue).desaturate(0.5).darken(0.75).rgb(),
  },
  orange: {
    base: baseOrange,
    lt: chroma(baseOrange).brighten(2.25).rgb(),
  },
  yellow: {
    base: baseYellow,
    lt: chroma(baseYellow).saturate(0.5).brighten(2.5).rgb(),
    xlt: chroma(baseYellow).saturate(0.5).brighten(3).rgb(),
    dk: chroma(baseYellow).darken(0.5).rgb(),
    xdk: chroma(baseYellow).darken(1.25).rgb(),
    xxdk: chroma(baseYellow).darken(1.5).rgb(),
  },
  red: {
    base: baseRed,
    lt: chroma(baseRed).brighten(2.5).rgb(),
    xlt: chroma(baseRed).brighten(3.25).rgb(),
    dk: chroma(baseRed).darken(1).rgb(),
    dstd: chroma(baseRed).desaturate(2).rgb(),
  },
  grey: {
    base: baseGrey,
    lt: chroma(baseGrey).brighten(0.5).rgb(),
    xlt: chroma(baseGrey).brighten(1).rgb(),
    xxlt: chroma(baseGrey).brighten(1.5).rgb(),
    dk: chroma(baseGrey).darken(0.5).rgb(),
    xdk: chroma(baseGrey).darken(1).rgb(),
    xxdk: chroma(baseGrey).darken(1.5).rgb(),
  },
};

// COLOR FUNCT
const color = <T extends keyof typeof colorObj>(
  hue: T,
  tone: keyof typeof colorObj[T] = "base",
  alpha = 1
) => {
  const currColor = values(colorObj[hue ?? ("green" as T)][tone]);

  return `rgba(${currColor[0]}, ${currColor[1]}, ${currColor[2]}, ${alpha})`;
};

export default color;
