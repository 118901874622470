import { ICellRendererParams } from "@ag-grid-community/core";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Table, message } from "antd";
import { ColumnType } from "antd/lib/table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import pick from "lodash/pick";
import { useState } from "react";
dayjs.extend(utc);

type Props = {
  params: ICellRendererParams;
  visitRecord: any;
  doSave: any;
  doDelete: any;
  doUpdate: any;
  anyLoading: boolean;
  vendorSavesRecords?: boolean;
};

const VisitTable = ({
  params,
  visitRecord,
  doUpdate,
  doSave,
  doDelete,
  anyLoading,
  vendorSavesRecords,
}: Props) => {
  const schedules = visitRecord?.schedules ?? [];
  process.env.NODE_ENV === "development" &&
    console.log("visitRecord", visitRecord);

  const [date, setDate] = useState(dayjs());
  const [completed, setCompleted] = useState(false);

  const colDefs: ColumnType<any>[] = [
    {
      title: "Date",
      dataIndex: "schedule_date",
      key: "date",
      render: (date, { id }) =>
        id === "footer" ? (
          <DatePicker
            disabledDate={(curr) =>
              schedules?.some((schedule: any) => {
                return (
                  dayjs(schedule.schedule_date).format("M/D/YYYY") ===
                  curr.format("M/D/YYYY")
                );
              })
            }
            format="M/D/YYYY"
            disabled={anyLoading}
            value={date}
            onChange={(val) => val && setDate(val)}
          />
        ) : (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {dayjs(date).format("M/D/YYYY")}
          </div>
        ),
      width: 220,
    },
    {
      width: 130,
      title: "Completed",
      dataIndex: "completed",
      key: "done",
      render: (value: boolean, record: any, index: number) => {
        return (
          <Checkbox
            {...(record.id === "footer"
              ? {
                  disabled: anyLoading,
                  checked: completed,
                  onChange: (e) => setCompleted(e.target.checked),
                }
              : {
                  checked: value,
                  onChange: (val) => {
                    doUpdate({
                      variables: {
                        id: record.id,
                        completed: val.target.checked,
                      },
                    });
                  },
                })}
          />
        );
      },
    },
    {
      width: 100,
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          Action
        </div>
      ),
      key: "action",
      render: (value: boolean, record: any, index: number) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: -4,
          }}
        >
          <Button
            danger={record.id !== "footer"}
            size="small"
            type={record.id === "footer" ? "link" : "text"}
            icon={
              record.id === "footer" ? <PlusOutlined /> : <DeleteOutlined />
            }
            disabled={anyLoading}
            onClick={async () => {
              const isCreateAction = record.id === "footer";
              if (!isCreateAction)
                return doDelete({
                  variables: {
                    id: record.id,
                  },
                });
              // IS create action
              const scheduleExists = schedules?.some((schedule: any) => {
                return (
                  dayjs(schedule.schedule_date).format("M/D/YYYY") ===
                  date.format("M/D/YYYY")
                );
              });
              if (scheduleExists)
                return message.error({
                  key: "save-schedule-error",
                  content: date.format("M/D/YYYY") + " is already scheduled!",
                });
              const serviceRecord = params.data;
              // Sanity check: unless !vendorSavesRecords, 
              // should not be able to schedule unsaved records
              if (vendorSavesRecords && !serviceRecord.isSaved) {
                console.log("!vendorSavesRecords && !serviceRecord.isSaved", {
                  vendorSavesRecords,
                  serviceRecord,
                });
                return message.error({
                  key: "save-schedule-error",
                  content: "Cannot schedule a service that is not saved in your system",
                });
              }
              const recordToSave = pick(serviceRecord, [
                "id",
                "proposal_service_id",
                "service_name",
                "property_name_abr",
                "proposal_start_date",
                "proposal_end_date",
                "proposal_number",
                "vendor_service_price",
                "vendor_id",
              ]) as any;
              recordToSave.property_name_abr =
                serviceRecord.property.PropertyNameAbr;

              const variables = {
                object: {
                  completed,
                  schedule_date: date.format("YYYY-MM-DD"),
                },
              } as any;
              if (serviceRecord.isSaved)
                variables.object.vendor_service_record_id = serviceRecord.id;
              else
                variables.object.vendorServiceRecord = {
                  data: recordToSave,
                };
              await doSave({
                variables,
              });
              setDate(dayjs());
              setCompleted(false);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 300,
      }}
    >
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <Table
          sticky
          columns={colDefs}
          dataSource={schedules}
          pagination={false}
          style={{ width: 450 }}
        />
      </div>
      <Table
        columns={colDefs}
        showHeader={false}
        dataSource={[{ id: "footer" }]}
        pagination={false}
        style={{ backgroundColor: "rgba(250, 250, 250)", width: 450 }}
      />
    </div>
  );
};

export default VisitTable;
