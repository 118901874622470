export const formatNumber = (
  n: string | number | undefined | null,
  allowDots?: boolean
) => String(n ?? "").replace(allowDots ? /[^\d.]/g : /\D/g, "");

export const toCurrency = (n: number | string) =>
  Number(n).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
