import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(utc)

export const dateStrToMomentUTCDate = (dateStr?: string) => {
  if (!dateStr) return undefined;
  if (dateStr.length === 10) {
    return dayjs.utc(dateStr);
  } else if (dateStr.length > 10) {
    // if format is Greater Than "YYYY-MM-DD" and contains TIME
    // convert date parsed as EST - TO start of UTC date
    return dayjs.utc(
      dayjs.utc(dateStr).tz("America/New_York").format("YYYY-MM-DD")
    );
  } else return undefined;
};
export default function dateComparator(
  filterDate: Date,
  cellValue: dayjs.Dayjs
) {
  const filterMomentDate = dayjs
    .utc(filterDate)
    .startOf("date");
  if (!cellValue || !filterMomentDate) return undefined;
  if (cellValue < filterMomentDate) {
    return -1;
  } else if (cellValue > filterMomentDate) {
    return 1;
  }
  // must equal
  else return 0;
}
