import Auth from "@aws-amplify/auth";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Button, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CognitoUserExt } from "shared-modules/general-hooks/user-context";
// five minutes
const TIME_BEFORE_EXPIRE = 5 * 60 * 1000
const INTREVAL_TIME = 3 * 1000

const refreshUserSession = async (setUser: (user?: CognitoUser | {}) => void) => {
  try {
    const cognitoUser: CognitoUserExt = await Auth.currentAuthenticatedUser();
    const currentSession: CognitoUserSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      async (err) => {
        if (err) throw err;
        setUser(await Auth.currentAuthenticatedUser());
      }
    );
  } catch (e) {
    setUser({})
  }
};

const UserExpiresModal: FC<{
  setUser: (user?: any) => void;
  user: CognitoUserExt;
}> = (props) => {
  const [modalType, setModalType] = useState<"none" | "expired" | "expiring">(
    "none"
  );

  const expirationSeconds =
    props.user?.signInUserSession?.idToken?.payload?.exp;
  useEffect(() => {
    const expiration = expirationSeconds * 1000;
    const checkStatus = async (firstRun?: boolean) => {
      const expires = expiration - Date.now();
      try {

          if (expires <= 0 && modalType !== "expired") {
            // expired
            await Auth.signOut();
            props.setUser({});
            setModalType("expired");
          } else if (expires < (TIME_BEFORE_EXPIRE) && modalType !== "expiring") {
            // expires shortly
            setModalType("expiring");
          }
        
      } catch (error) {
        props.setUser({});
      }
    };
    // checkStatus(true);
    const myIntreval = setInterval(checkStatus, INTREVAL_TIME);
    return () => clearInterval(myIntreval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationSeconds, modalType]);
  let title = "",
    content = "";
  if (modalType === "expired") {
    title = "Logged Out";
    content = "You have been logged out due to inactivity.";
  } else if (modalType === "expiring") {
    title = "Stay logged in";
    content =
      "For your security, you will automatically be logged out shortly. Please click below to stay signed in.";
  }
  const onOk = async () => {
    if (props.user) {
      await refreshUserSession(props.setUser);
      setModalType("none");
    }
  };
  const onCancel = () => modalType === "expired" && setModalType("none")
  const logOut = async () => {
    await Auth.signOut();
    props.setUser({});
    setModalType("none");
  };
  return (
    <Modal
      visible={!["none", "refreshing"].includes(modalType)}
      title={title}
      onCancel={onCancel}
      footer={[
        (modalType === "expired" && <Button key="dismiss" onClick={logOut}>
          Dismiss
        </Button>),
        (modalType === "expiring" && <Button key="cancel" onClick={logOut}>
          Log Out Now
        </Button>),
        (modalType === "expiring" && <Button key="ok" type="primary" onClick={onOk}>
          Stay Signed In
        </Button>)
      ]}
    >
      {content}
    </Modal>
  );
};

export default React.memo(UserExpiresModal);
