import { createContext } from "react";
import { CognitoUser } from "@aws-amplify/auth";

type UserRoles = "vendor_mgmt" | "vendor";
export interface CognitoUserExt extends CognitoUser {
  attributes: {
    sub: string
  }
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: {
        exp: number;
        user: string;
        claims: {
          "x-hasura-allowed-roles": UserRoles[];
          "x-hasura-default-role": UserRoles;
          "x-hasura-user-id": string;
        };
        "https://hasura.io/jwt/claims": string;
      };
    };
  };
}

type UserContextType = {
  user?: CognitoUserExt;
  setUser(user?: CognitoUserExt | {}): void;
};
export const UserContext = createContext<UserContextType | {}>({});
