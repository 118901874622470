import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { dateStringToReadable, isNullish, isNumeric, toDollars, trimWS } from 'helpers';
import { Redirect, useHistory } from 'react-router-dom';
import { CardedProposal } from './proposal-carded';
import { Col, Divider, Modal, notification, Row, Typography, Grid, message, Spin } from 'antd';
import { updateProposalPricingMutation, updateProposalStatusMutation } from '../mutations';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PROPOSAL_STAGES } from 'Globals.js'
import { getNestedValue, setNestedValue } from './proposal-helpers';
import { useGetProposal } from './useGetProposal';
import { useSetProposalViewed } from './useSetProposalViewed';
import { MainHeader } from 'shared-modules/general-components/MainHeader';
import InvoicesModal from './InvoicesModal';
import { joinWords } from 'shared-modules/helpers/strings';

const DetailRow = ({ title, text }) => <Row>
  <Col xs={{ span: 8 }} md={{ span: 6 }} lg={{ span: 4 }} >
    <strong>{title}</strong>
  </Col>
  <Col xs={{ span: 16 }} md={{ span: 18 }} lg={{ span: 20 }} >
    {text}
  </Col>
</Row>

const ViewProposal = ({ match, vendorAccessKey = false }) => {
  const { md } = Grid.useBreakpoint()
  const history = useHistory()
  const id = match?.params?.id; 
  const { setProposalViewed } = useSetProposalViewed()
  // query proposal
  const { proposal, loading, error, refetchProposal } = useGetProposal(id, vendorAccessKey)
  useEffect(() => {
      if (proposal?.id) {
        setProposalViewed({ variables: { data: JSON.stringify({ 
          proposalID: proposal.id,
          orientation: window.orientation,
          userAgent: window.navigator.userAgent||window.navigator.vendor||window.opera,
          windowHeight: window.innerHeight, 
          windowWidth: window.innerWidth,
          maxTouchPoints: navigator.maxTouchPoints,
        }) } })
      }
  }, [proposal?.id, setProposalViewed])
  // state
  const [editMap, setEditMap] = useState(false);
  const [selectedCard, setSelectedCard] = useState(undefined)
  const [invoicesModalVisible, setInvoicesModalVisible] = useState(false)
  // state functions
  const clearForm = () => {
    setEditMap(false)
    setSelectedCard(null)
  }
  // mutations
  const [ updatePricing, { loading: pricingMutationLoading } ] = useMutation(updateProposalPricingMutation);
  const [ updateStatus, { loading: statusMutationLoading } ] = useMutation(updateProposalStatusMutation);
  // safe to use proposal after this:
  // will be false before query has run
  if (loading) return <Spin  />
  if (error || (!loading && proposal === false)) {
    message.error({
      content: <div>There was an error retreiving this proposal. Please contact Gras Lawn Support at <a href='mailto:it@graslawn.com'>it@graslawn.com</a></div>,
      key: "error-retreiving-proposal"
    })
    return <Redirect to='/' />
  }
  // will be null if gql query is blank,
  if (!proposal) return <Redirect to='/' />
  // destructure proposal constants
  const {
    proposal_invoice_type,
    proposal_number,
    opportunity,
    proposal_start_date,
    proposal_end_date,
    status = '',
    id: proposalID,
    vendorProposalServices = [],
  } = proposal


  // make lookup of initial values for fast retreival
  const dbValueLookup = {};
  vendorProposalServices.forEach(service => {
    dbValueLookup[service.id] = { value: service.vendor_service_price }
    const items = vendorProposalServices?.vendorProposalItems
    if (Array.isArray(items)) {
      items.forEach(item => dbValueLookup[service.id][item.id] = item.vendor_item_price)
    }
  })
  // end
  
  // useful consts
  const isContract = proposal_invoice_type === 'Fixed Payment'
  const allServicesPriced = vendorProposalServices.every(({ id, vendor_service_price }) => !isNullish(vendor_service_price) || !isNullish(editMap[id]?.value))
  const pricingEditable = status.toLowerCase() === 'bidding' && !pricingMutationLoading && !statusMutationLoading

  // set proposal total 
  let proposalTotal = 0;
  if (proposal?.vendorProposalServices) proposalTotal = proposal?.vendorProposalServices
    .reduce((total, { id, vendor_service_price, occurrences, proposal_service_invoice_type, proposal_service_as_needed }) => {
      if (proposal_service_as_needed || proposal_service_invoice_type.includes('T&M') || proposal_service_invoice_type.toLowerCase() === 'optional') return total
      const serviceRawValue = editMap[id]?.value ?? vendor_service_price ?? 0
      const servicePrice = Number.isNaN(serviceRawValue) ? 0 : Number(serviceRawValue)
      const occurs = isContract ? occurrences : 1
      return total + (servicePrice * occurs)
    }, 0)

  // there are changes to persist...
  const updatedPricingToSave = editMap 
    //  there is something to save
    && Object.entries(editMap)
      .some(([serviceID, { value: serviceValue, ...items }]) => {
        const dbServiceValue = dbValueLookup?.[serviceID]?.value
        // either service has changed or one of its items
        return (
          (
            // service has a value
            !isNullish(serviceValue)
            // and that value is different from server 
            && `${serviceValue}` !== (isNumeric(dbServiceValue) ? `${dbServiceValue.toFixed(2)}` : dbServiceValue)
            // and not just different because server is undefined and value is blank
            && !(
              serviceValue === '' && isNullish(dbServiceValue) 
            )
          )  
          || (
            // items to save
            Object.entries(items).some(([itemID, { value: itemValue }]) => {
              const dbItemValue = dbValueLookup?.[serviceID]?.[itemID]?.value
              return (
                // item has a value
                !isNullish(itemValue)
                // and that value is different from server 
                && `${itemValue}` !== (isNumeric(dbItemValue) ? `${dbItemValue.toFixed(2)}` : dbItemValue)
                // and not just different because server is undefined and value is blank
                && !(
                  itemValue === '' && isNullish(dbItemValue) 
                )
              )
            })
          )
        )          
      })

  const onSaveProposal = async (_, isSubmit) => {
    try {
      const servicesToUpdate = [], itemsToUpdate = []
      for (let [serviceID, { value: vendor_service_price, ...items }] of Object.entries(editMap)) {
        if (vendor_service_price !== '') servicesToUpdate.push({ id: serviceID, vendor_service_price })
        itemsToUpdate.push(
          ...Object.entries(items)
            .filter(([
              _,
              { value: vendor_item_price }
            ]) => vendor_item_price !== '')
            .map(([
              itemID,
              { value: vendor_item_price }
            ]) => ({ id: itemID, vendor_item_price }))
        )
      }
      await updatePricing({ 
        variables: { servicesToUpdate, itemsToUpdate }
      })
      if (!isSubmit) {
        message.success({ key: 'save-submit', content: 'Saved' })
        clearForm();  
        refetchProposal();
      }
      
    } catch ({message}) {
      notification.error({
        message: 'Error',
        description: message,
        placement: 'bottomRight'
      })

    }
  }
  const submitProposal = async () => {
    try {
      await updateStatus({ 
        variables: { id: proposalID }
      })
      clearForm()
      refetchProposal()
      if (vendorAccessKey) {
        Modal.info({
          title: 'Submitted',
          content: 'Thanks! Your proposal has been submitted. It will be reviewed by the property manager. You may now close this page.'
        })
      } else message.success({ key: 'save-submit', content: 'Submitted!'})
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'bottomRight'
      })
    }
  }
  const onSubmitProposal = async () => {
    if (!allServicesPriced) notification.error({ message: 'Missing data', description: 'Please complete pricing for all services.', placement: 'bottomRight'  })
    else {

      Modal.confirm({
        title: 'Are you sure you are ready to submit?',
        content: <div style={{ marginTop: '25px' }}>
          <p><Typography.Text>You will not be able to make any changes once submitted.</Typography.Text></p>
          <p><Typography.Text type='secondary'>Please note: By submitting this proposal, Contractor agrees not to compete with Gras Lawn LLC as an employee, Sub-Contractor, Contractor or in any other capacity by providing the services to the clients which are the subject of this Proposal.</Typography.Text></p>
        </div>,
        onOk: async () => {
          if (Object.keys(editMap).length > 0) await onSaveProposal(null, true);
          submitProposal();
        },
        icon: false,
        // icon: <ExclamationCircleOutlined style={{ color: 'red'}} />,
      })
    }
  }
  const onClickInvoices = () => setInvoicesModalVisible(true)
  const onEditValueChange = (path, inputType) => e => {
    const [serviceID, ] = path
    let newVal = (e.target.value || '').trim()
    // const oldVal = getNestedValue(editMap, path)
    if (inputType === 'currency-blur' && updatedPricingToSave) message.info({ key: 'remember-to-save', content: 'Remember to save your changes', duration: .75 })
    setEditMap(oldMap => {
      if (
        inputType === 'currency' 
        && !isNumeric(newVal)
        && (newVal !== '' || newVal === oldMap === '')
      ) return oldMap
      const newMap = setNestedValue(oldMap, path, newVal)
      if (inputType === 'currency-blur') {
        if (newVal === '') {
          // delete
          delete getNestedValue(newMap, path)
        }
        newVal = isNumeric(newVal) && newVal !== '' ? parseFloat(newVal).toFixed(2) : null
        return newMap
      }
      // set new value
      // if item: 
      if (path.length === 2 && newMap[serviceID]) {
        // editing item price, sum items for service total
        const { value, ...items } = newMap[serviceID]
        const dbService = proposal.vendorProposalServices.find(serv => serv.id === serviceID)
        const dbItems = dbService?.vendorProposalItems || []
        const newServiceValue = dbItems.reduce((acc, { id, quantity, vendor_item_price }) => {
          const itemPrice = !isNullish(items[id]) ? items[id].value : vendor_item_price ?? 0
          const linePrice = Number(itemPrice) * quantity
          return acc + linePrice
        }, 0).toFixed(2)
        newMap[serviceID].value = newServiceValue
      }
      return newMap
    })
  };

  // header options right menu dropdown
  const menuItems = []
  if (status.toLowerCase() === 'accepted' && !vendorAccessKey) menuItems.push({ key: 'invoices', title: <Typography.Text >Invoices</Typography.Text>, onClick: onClickInvoices }) 
  if (status.toLowerCase() === 'bidding') menuItems.push({ key: 'submit', title: <Typography.Text disabled={!allServicesPriced}>Submit</Typography.Text>, onClick: onSubmitProposal })
  if (updatedPricingToSave) menuItems.push(
    { key: 'save', onClick: onSaveProposal, title: 'Save Changes' },
    { key: 'clear', onClick: clearForm, title: 'Clear Edits' }
  )
  // property data for header
  const {
    accountOwner,
    PropertyAddressLine1,
    PropertyAddressLine2,
    PropertyName,
    PropertyNameAbr,
    PropertyZipCode: ZIP,
    PropertyCity: CITY,
    PropertyStateProvinceCode: ST
  } = (opportunity?.property || {})
  const AddressOutput = <>
    {trimWS(PropertyAddressLine1)}
    {trimWS(PropertyAddressLine2) && <><br />{trimWS(PropertyAddressLine2)}</>}
    {
      trimWS(CITY) && trimWS(ST) && trimWS(ZIP) && <><br />
      {trimWS(CITY)}
      {trimWS(ST) &&  <>,&nbsp;{trimWS(ST)}</>}
      {trimWS(ZIP) &&  <> {trimWS(ZIP)}</>}
      </>
    }
  </>

  return <>
    <InvoicesModal
      visible={invoicesModalVisible}
      setVisible={setInvoicesModalVisible}
      proposalID={proposalID}
      proposal_end_date={proposal_end_date}
      status={status}
    />
    <MainHeader
      title={`Proposal`}
      menuItems={menuItems}
      vendorAccessKey={vendorAccessKey}
      backIcon={<ArrowLeftOutlined onClick={() => history.push(`/view-proposals`)} />}
    />
    <div style={{ maxWidth: '1250px', padding: '15px'}}>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12 }} >
          <DetailRow title='Property:' text={trimWS(isNullish(PropertyNameAbr) || PropertyNameAbr.trim().toUpperCase() === 'NEED SHORT NAME' ? PropertyName : PropertyNameAbr)} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
          <DetailRow title='Address:' text={AddressOutput} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
          <DetailRow title='Account Manager:' text={accountOwner ? <>{joinWords(accountOwner.FirstName, accountOwner.LastName)}<br />{accountOwner.Email && <a href={'mailto:' + accountOwner.Email} >{accountOwner.Email}</a>}</> : ''} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <DetailRow title='Proposal:' text={<>#{proposal_number} - {trimWS(opportunity?.OpportunityName)}</>} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
          <DetailRow title='Service Dates:' text={<>{dateStringToReadable(proposal_start_date)}-{dateStringToReadable(proposal_end_date)}</>} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
          <DetailRow title='Stage:' text={PROPOSAL_STAGES[status]} />
          {!md && <Divider style={{ margin: '5px 0'}} />}
          <DetailRow title='Total:' text={!!proposalTotal && toDollars(proposalTotal)} />
        </Col>
      </Row>
    </div>
    <CardedProposal { ...{proposal, editMap, onEditValueChange, isContract, selectedCard, setSelectedCard, pricingEditable} } />
  </>

}
export default ViewProposal;