import { ICellRendererParams } from "@ag-grid-community/core";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import React, { FC } from "react";
import useAddRecordSchedule from "./useAddRecordSchedule";
import useDeleteRecordSchedule from "./useDeleteRecordSchedule";
import useUpdateRecordSchedule from "./useUpdateRecordSchedule";
import VisitTable from "./VisitTable";

const RecordSchedules: FC<{
  params: ICellRendererParams;
  vendorSavesRecords?: boolean;
}> = ({ params, vendorSavesRecords }) => {
  const { record } = params.data;
  const { doAction: doSave, loading: saveLoading } = useAddRecordSchedule(
    params.data,
    params.node
  );
  const { doAction: doDelete, loading: deleteLoading } =
    useDeleteRecordSchedule(params.data, params.node);
  const { doAction: doUpdate, loading: updateLoading } =
    useUpdateRecordSchedule(params.data, params.node);

  const hasSchedules = record?.schedules?.length > 0;

  return (
    <Popover
      destroyTooltipOnHide
      content={
        <VisitTable
          params={params}
          visitRecord={record}
          doSave={doSave}
          doDelete={doDelete}
          doUpdate={doUpdate}
          anyLoading={saveLoading || deleteLoading || updateLoading}
          vendorSavesRecords={vendorSavesRecords}
        />
      }
      trigger="click"
    >
      <div>
        {record?.schedules?.length ?? 0}
        <Button
          type="link"
          icon={hasSchedules ? <EditOutlined /> : <PlusOutlined />}
        />
      </div>
      {/* <Button>{record?.schedules?.length}</Button> */}
    </Popover>
  );
};

export default RecordSchedules;
