import { gql, useQuery } from "@apollo/client";

const PROPOSAL_QUERY = gql`
  query MyQuery($where: vendorProposals_bool_exp) {
    vendorProposals(where: $where) {
      id
      proposal_invoice_type
      proposal_number
      proposal_start_date
      proposal_end_date
      status
      opportunity: aspireOpportunity {
        OpportunityName
        property {
          PropertyAddressLine1
          PropertyAddressLine2
          PropertyName
          PropertyNameAbr
          PropertyID
          PropertyZipCode: PropertyAddressZipCode
          PropertyCity: PropertyAddressCity
          PropertyStateProvinceCode: PropertyAddressStateProvinceCode
          accountOwner {
            FirstName
            LastName
            Email
          }
        }
      }
      vendorProposalServices(order_by: {sort_order: asc}) {
        id
        occurrences
        proposal_service_invoice_type
        service_description
        service_name
        vendor_service_price
        proposal_service_as_needed
        vendorProposalItems(order_by: {sort_order: asc}) {
          id
          item_name
          quantity
          unit_type
          vendor_item_price
        }
      }
    }
  }
`
export const useGetProposal = (id, vendorAccessKey) => {
  const { loading, data: proposalData = {}, refetch, error } = useQuery(PROPOSAL_QUERY, {
    variables: vendorAccessKey ? {} : { 
      where:  {
        id: {
          _eq: id
        }
      }
    }
  });
  const { vendorProposals } = proposalData;
  if (process.env.NODE_ENV === 'development') console.log('PROPOSAL: ', vendorProposals?.[0])
  if (error) console.error('PROPOSAL ERROR: ', error)
  return { proposal: vendorProposals?.[0] ?? false , loading, error, refetchProposal: refetch };
}