import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { Typography } from "antd";
import { toDollars } from "helpers/helper-functions";
import { clone, set } from "lodash";
import { AgGridLinkRenderer } from "shared-modules/general-components/ag-grid/helpers";
import { addressFromFields } from "shared-modules/helpers/addressFromFields";
import { dateStrToMomentUTCDate } from "shared-modules/helpers/ag-grid/dateComparator";
import RecordSchedules from "./cols/RecordSchedules";
import SaveRecordCheckbox from "./cols/SaveRecordCheckbox";
import useGetVendorSavesRecords from "pages/schedule/schedule-board/schedule-board2/useGetVendorSavesRecords";
export const idGetter = (data: any, partial = false) =>
  `${data.service_number}-${data.occurrence ?? 1}` +
  (partial ? "" : `-${data.occurrences ?? 1}`);
// export const recordGetter = (data: any) =>
//   (data.vendorServiceRecords ?? []).find(
//     ({ id }: any) => id === idGetter(data)
//   );
const useColDefs: () => ColDef[] = () => {
  const { data: vendorSavesRecords } = useGetVendorSavesRecords();

  return [
    {
      colId: "record_id",
      headerName: "Record ID",
      valueGetter: ({ data }) => data.id ?? data.record?.id,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    ...(vendorSavesRecords
      ? [
          {
            colId: "saved",
            headerName: "Saved",
            valueGetter: ({ data }) => (data.isSaved ? "Yes" : "No"),
            cellRenderer: (params: ICellRendererParams) => (
              <SaveRecordCheckbox params={params} />
            ),
            filter: "agTextColumnFilter",
            sortable: true,
            filterParams: { newRowsAction: "keep" },
            floatingFilter: true,
          } as ColDef,
        ]
      : []),
    {
      colId: "schedules",
      headerName: "Visits",
      filter: "agNumberColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
      sortable: true,
      valueGetter: ({ data }) => data?.record?.schedules?.length ?? (vendorSavesRecords ? "" : 0),
      cellRenderer: (params: ICellRendererParams) => {
        return params?.data?.isSaved || vendorSavesRecords === false ? (
          <RecordSchedules
            params={params}
            vendorSavesRecords={vendorSavesRecords}
          />
        ) : null;
      },
    },
    {
      colId: "completed",
      headerName: "Completed",
      filter: "agTextColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
      sortable: true,
      valueGetter: ({ data }) =>
        data?.record?.schedules?.some((schedule: any) => schedule?.completed)
          ? "Yes"
          : "No",
      cellRenderer: (params: ICellRendererParams) => {
        return params?.data?.isSaved || !vendorSavesRecords ? (
          <Typography.Text
            type={params.value === "Yes" ? "success" : "secondary"}
          >
            {params.value}
          </Typography.Text>
        ) : null;
      },
    },
    {
      colId: "proposal_number",
      headerName: "Proposal #",
      // field: "proposal_number",
      valueGetter: ({ data }) =>
        data?.proposal_number ?? data?.record?.proposal_number,
      cellRenderer: (params: ICellRendererParams) => (
        <AgGridLinkRenderer
          linkIdPath="data.proposal_id"
          pageLink="PROPOSAL_LINK"
          params={params}
        />
      ),
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "property",
      headerName: "Property",
      valueGetter: ({ data }) =>
        data?.property?.PropertyNameAbr ?? data?.record?.property_name_abr,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <AgGridLinkRenderer
            linkIdPath="data.property.PropertyID"
            pageLink="PROPERTY_LINK"
            params={set(
              clone(params),
              "data.property",
              params?.data?.property ?? params?.data?.record?.property
            )}
          />
        );
      },
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "service",
      headerName: "Service",
      valueGetter: ({ data }) =>
        data?.service_name ?? data?.record?.service_name,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "service-price",
      headerName: "Price",
      valueGetter: ({ data }) =>
        data?.vendor_service_price ?? data?.record?.vendor_service_price,
      valueFormatter: ({ value }) => (value ? toDollars(value) : ""),
      sortable: true,
      filter: "agNumberColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "occur",
      headerName: "Occurrence",
      valueGetter: ({ data }) => {
        const id = data?.id ?? data?.record?.id;
        return Number(typeof id === "string" ? id.split("-")[1] : "1");
      },
      filter: "agNumberColumnFilter",
      sortable: true,
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "occurs",
      headerName: "Of",
      valueGetter: ({ data }) => {
        const id = data?.id ?? data?.record?.id;
        return Number(typeof id === "string" ? id.split("-")[2] : "1");
      },
      sortable: true,
      filter: "agNumberColumnFilter",
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
    {
      colId: "proposal_dates",
      headerName: "Contract Dates",
      minWidth: 350,
      valueGetter: ({ data }) => {
        const startDate =
            data?.proposal_start_date ?? data?.record?.proposal_start_date,
          endDate = data?.proposal_end_date ?? data?.record?.proposal_end_date;
        return `${dateStrToMomentUTCDate(startDate)?.format("M/D/YYYY") ?? ""}${
          startDate && endDate ? " - " : ""
        }${dateStrToMomentUTCDate(endDate)?.format("M/D/YYYY") ?? ""}`;
      },
    },
    {
      colId: "address",
      headerName: "Address",
      valueGetter: ({ data }) => {
        const property = data?.property ?? data?.record?.property;
        return addressFromFields({
          addr1: property?.PropertyAddressLine1,
          addr2: property?.PropertyAddressLine2,
          city: property?.PropertyAddressCity,
          state: property?.PropertyAddressStateProvinceCode,
          zip: property?.PropertyAddressZipCode,
        });
      },
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: { newRowsAction: "keep" },
      floatingFilter: true,
    },
  ];
};

export default useColDefs;
