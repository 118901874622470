import { LogoutOutlined, MenuOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Grid,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import useLogout from "helpers/useLogout";
import React from "react";
import color from "shared-modules/helpers/colors";
import PageStatusTags from "./PageStatusTags";
import SubTitleDivider from "./SubTitleDivider";
import TitleDivider from "./TitleDivider";
const { useBreakpoint } = Grid;

export const MainHeader = (props) => {
  const { sm, md, xl, lg } = useBreakpoint();
  const menuItems = [];
  const [logout] = useLogout();
  if (props.menuItems) menuItems.push(...props.menuItems);
  menuItems.push({
    key: "logout",
    title: "Sign Out",
    icon: <LogoutOutlined />,
    onClick: () => logout(),
    danger: true,
  });

  const OptionsMenu = xl ? (
    <Space>
      {menuItems.map((Item, i) => {
        return typeof Item === "object" ? (
          <Button
            type={Item.buttonType ?? "default"}
            key={Item.key}
            disabled={Item.disabled}
            icon={Item.icon}
            onClick={Item.onClick}
            danger={Item.danger}
          >
            {Item.title}
          </Button>
        ) : (
          Item()
        );
      })}
    </Space>
  ) : (
    <Menu>
      {menuItems.map((Item) => (
        <Menu.Item
          key={Item.key}
          disabled={props.disabled}
          icon={Item.icon}
          onClick={Item.onClick}
        >
          {typeof Item === "function" ? <Item buttonType="text" /> : Item.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  // return <PageHeader
  //   className="site-page-header"
  //   { ...rest }

  //   extra={[
  //     ...extra.map((el, i) => ({ ...el, i})),
  //     <Button key='logout' onClick={() => Auth.signOut().then(_ => setUser({}))}>Sign Out</Button>
  //   ]}
  // />}

  let formattedTitle, formattedSubTitle;

  if (Array.isArray(props.title)) {
    const titleList = props.title.map((str, i) => {
      return (
        <React.Fragment key={"titleStr" + i}>
          <span>{str}</span>
          {i < props.title.length - 1 && <TitleDivider />}
        </React.Fragment>
      );
    });

    formattedTitle = titleList;
  } else {
    formattedTitle = props.title;
  }

  // console.log({ subtitle: props.subTitle });

  if (Array.isArray(props.subTitle)) {
    const subTitleList = props.subTitle.map((item, i) => {
      return (
        <React.Fragment key={"subTitleStr" + i}>
          {item}
          {i < props.subTitle.length - 1 && <SubTitleDivider />}
        </React.Fragment>
      );
    });

    formattedSubTitle = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {subTitleList}
      </div>
    );
  } else {
    formattedSubTitle = props.subTitle;
  }

  return (
    <Row
      align="middle"
      style={{
        padding: "16px 29px",
        fontSize: 16,
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
        zIndex: 100,
        marginRight: -5,
        marginLeft: -5,
        minHeight: 80,
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
      }}
    >
      <Space style={{ display: "flex", alignItems: "center" }}>
        {props.backIcon || <MenuOutlined onClick={props.toggleSider} />}
        <img
          src="/img/logoG192.svg"
          alt="Logo"
          className="logo"
          style={{
            maxWidth: "100%",
            maxHeight: 45,
            marginLeft: md ? 15 : 0,
            marginRight: 15,
            visibility: props.siderOpened ? "hidden" : "visible",
            display: props.siderOpened ? "none" : "block",
            objectFit: "contain",
          }}
        />
        {typeof props.title === "string" || Array.isArray(props.title) ? (
          lg ? (
            <div
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
                height: 30,
                marginTop: -2,
              }}
            >
              <div>
                {!!props.subTitle && (
                  <Typography.Title
                    level={3}
                    {...props?.subTitleProps}
                    style={{
                      color: color("grey", "dk"),
                      marginBottom: 2,
                      textTransform: "uppercase",
                      fontSize: 11,
                      lineHeight: 1,
                      display: "flex",
                      alignItems: "flex-end",
                      letterSpacing: "0.1em",
                      fontWeight: 400,
                      ...props?.subTitleProps?.style,
                    }}
                  >
                    {formattedSubTitle}
                  </Typography.Title>
                )}
                <Typography.Title
                  level={2}
                  {...props?.titleProps}
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    fontSize: 27,
                    color: color("navy"),
                    display: "flex",
                    alignItems: "center",
                    ...props?.titleProps?.style,
                  }}
                >
                  {formattedTitle}
                </Typography.Title>
              </div>
              {Array.isArray(props.statusTags) ? (
                <PageStatusTags size="lg" statusTags={props.statusTags} />
              ) : (
                props.statusTags
              )}
            </div>
          ) : (
            <div>
              <Row>
                <Col span={24}>
                  <Space
                    size="large"
                    style={{
                      alignItems: "center",
                      marginBottom: -2,
                    }}
                  >
                    <div>
                      {!!props.subTitle && (
                        <Typography.Title
                          level={3}
                          {...props?.subTitleProps}
                          style={{
                            color: color("grey"),
                            marginBottom: 2,
                            textTransform: "uppercase",
                            fontSize: 11,
                            lineHeight: 1,
                            ...props?.subTitleProps?.style,
                          }}
                        >
                          {formattedSubTitle}
                        </Typography.Title>
                      )}
                      <Typography.Title
                        level={4}
                        {...props?.titleProps}
                        style={{
                          margin: 0,
                          lineHeight: 1,
                          ...props?.titleProps?.style,
                        }}
                      >
                        {formattedTitle}
                      </Typography.Title>
                    </div>
                    {Array.isArray(props.statusTags) ? (
                      <PageStatusTags statusTags={props.statusTags} size="sm" />
                    ) : (
                      props.statusTags
                    )}
                  </Space>
                </Col>
              </Row>
            </div>
          )
        ) : (
          props.title
        )}
      </Space>
      {!!props.centeredContent && <div>{props.centeredContent}</div>}
      {!!menuItems?.length && (!props.siderOpened || sm) && (
        <div>
          <Row justify="end">
            {xl ? (
              OptionsMenu
            ) : (
              <Dropdown trigger={["click"]} overlay={OptionsMenu}>
                {md ? <Button>Options</Button> : <MoreOutlined />}
              </Dropdown>
            )}
          </Row>
        </div>
      )}
    </Row>
  );
};
