import { Layout, Menu } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "context/app-context";
const { Content, Sider } = Layout;

export const MainMenu = (props) => {
  const { children, links, match } = props;
  const { sider } = useContext(AppContext);

  return (
    <Layout style={{ height: "100vh" }} className="site-layout-background">
      <Sider
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible
        collapsed={!sider.siderOpened}
        // zeroWidthTriggerStyle={{ backgroundColor: "red", width: "100vw" }}
      >
        <div style={{ padding: "15px 30px 10px 30px", textAlign: "center" }}>
          <img
            src="/img/logo-white.png"
            alt="Logo"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[match.path]}>
          {links
            .filter(({ linkText }) => linkText)
            .map(({ path, linkText }) => (
              <Menu.Item key={path}>
                <Link to={path}>{linkText}</Link>
              </Menu.Item>
            ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          className="site-layout-background"
          style={{ height: "100vh", overflowY: "auto" }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
