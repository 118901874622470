import { Modal } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ModalUnmountOnPathChange = () => {
  const location = useLocation();
  useEffect(() => Modal.destroyAll(), [location.pathname]);
  return false;
};

export default ModalUnmountOnPathChange;
