import { ICellRendererParams } from "@ag-grid-community/core";
import { get, omit } from "lodash";
import { Link } from "react-router-dom";
import { PAGE_LINKS } from "shared-modules/globals/globals";

type LinkRendererProps = {
  params: ICellRendererParams;
  // anchorProps?: React.HTMLProps<HTMLAnchorElement>;
} & (
  | {
      linkIdPath: string;
      pageLink: keyof typeof PAGE_LINKS;
    }
  | {
      customPath?: string;
      externalLink?: boolean;
    }
) &
  Omit<React.ComponentProps<Link>, "to">;
export const AgGridLinkRenderer = (props: LinkRendererProps) => {
  const remainingProps: Omit<
    LinkRendererProps,
    "to" | "linkIdPath" | "customPath" | "pageLink" | "params"
  > = omit(props, "to", "linkIdPath", "customPath", "pageLink", "params");
  let link: string | undefined;
  let externalLink = false;
  if ("linkIdPath" in props) {
    const linkID = get(props.params, props.linkIdPath);
    link = linkID ? PAGE_LINKS[props.pageLink] + linkID : undefined;
  } else {
    link = props.customPath;
    externalLink = !!props.externalLink;
  }
  return props.params.data && link ? (
    externalLink ? (
      <a href={link} target={props.target}>
        {props.params.value}
      </a>
    ) : (
      <Link to={link} {...remainingProps}>
        {props.params.value}
      </Link>
    )
  ) : (
    <>{props.params.value}</>
  );
};
