import React from "react";
import { StandardPage } from "shared-modules/general-components/standard-page/StandardPage";
import AllActiveProposalServices from "./AllActiveProposalServices";

const AllActiveProposalServicesPage = () => {
  return (
    <StandardPage title="Contracted Services">
      <AllActiveProposalServices />
    </StandardPage>
  );
};

export default AllActiveProposalServicesPage;
