import { isNullish } from "../../../helpers";

export const setNestedValue = (target, path, newVal) => {
  const toReturn = { ...(target || {}) }
  const [serviceID, itemId] = path;
  if (path.length === 1) {
    // setting service
    // if (newVal === '') delete toReturn[serviceID]
    // else toReturn[serviceID] = { value: newVal }
    toReturn[serviceID] = { value: newVal }
  }
  if (path.length === 2) {
    // setting item
    // if (newVal === '' && !isNullish(toReturn?.[serviceID]?.[itemId])) {
    //   // blank - delete item
    //   delete toReturn[serviceID][itemId]
    //   // if no more items - delete service too
    //   if (Object.keys(toReturn[serviceID]).filter(key => key !== 'value').length === 0) delete toReturn[serviceID] 
    // }
    // else {
      if (isNullish(toReturn[serviceID])) toReturn[serviceID] = {}
      toReturn[serviceID][itemId] = { value: newVal }
    // }
  }
  return toReturn
}
export const getNestedValue = (target, path) => [ ...path, 'value' ].reduce((lastVal, prop) => lastVal?.[prop] ?? undefined, target)
