import {
  AgGridEvent,
  ColumnApi,
  GridApi,
  GridReadyEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReactProps } from "@ag-grid-community/react";
import { Dropdown, Menu } from "antd";
import React, { cloneElement, FC, useContext, useState } from "react";
import { AppContext } from "context/app-context";

interface Props extends AgGridReactProps {
  tableKey: string;
  dataLoading?: boolean;
}
type AgCallbackProps = { api: GridApi; columnApi: ColumnApi };

const GrasAgGrid: FC<Props> = ({ tableKey, ...rest }) => {
  const { children, gridOptions, columnDefs, ...agProps } = rest;
  // </START add header tooltip to coldefs as children>
  const _children = [];
  if (children?.length) {
    for (let col of children) {
      _children.push(
        cloneElement(col, { headerTooltip: col.props.headerName })
      );
    }
  }
  // </END add header tooltip to coldefs as children>
  const { filterModels, sortModels } = useContext(AppContext);
  const [listApis, setListApis] = useState<AgGridEvent | null>(null);
  const onClearFilters = () => {
    if (listApis) {
      listApis.api.setFilterModel({});
      const cols = listApis.columnApi.getColumnState();
      if (cols) {
        const sorts = cols.map(({ colId }) => ({ colId, sort: null }));
        listApis.columnApi.applyColumnState({ state: sorts });
      }
    }
  };

  const onFilterSortChange = (changeProps: AgCallbackProps) => {
    const sortStates = changeProps.columnApi
      .getColumnState()
      .filter(({ sort }) => sort)
      .map(({ sort, colId }) => ({ colId, sort }));
    if (filterModels?.current)
      filterModels.current[tableKey] = changeProps.api.getFilterModel();
    if (sortModels?.current) sortModels.current[tableKey] = sortStates;
  };
  console.log({ gridOptions });
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={onClearFilters} key="clear">
            Clear Filters
          </Menu.Item>
        </Menu>
      }
      trigger={["contextMenu"]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%" }}
      >
        <AgGridReact
          tooltipShowDelay={500}
          onFilterChanged={onFilterSortChange}
          onSortChanged={onFilterSortChange}
          onFirstDataRendered={({ api, columnApi }: AgCallbackProps) => {
            if (filterModels?.current?.[tableKey])
              api.setFilterModel(filterModels.current[tableKey]);
            if (sortModels?.current?.[tableKey])
              columnApi.applyColumnState({
                state: sortModels.current[tableKey],
              });
          }}
          onGridReady={(props: GridReadyEvent) => {
            props.api.sizeColumnsToFit();
            setListApis(props);
          }}
          suppressHorizontalScroll
          {...agProps}
          // add tooltip to header
          columnDefs={
            columnDefs?.length
              ? columnDefs.map((col) => ({
                  headerTooltip: col.headerName,
                  ...col,
                }))
              : undefined
          }
          gridOptions={{
            ...gridOptions,
            columnDefs: gridOptions?.columnDefs?.length
              ? gridOptions.columnDefs.map((col) => ({
                  headerTooltip: col.headerName,
                  ...col,
                }))
              : undefined,
          }}
          children={_children}
        />
      </div>
    </Dropdown>
  );
};
export default GrasAgGrid;
