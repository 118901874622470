import { Button } from "antd";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import GrasAgGrid from "shared-modules/general-components/ag-grid/GrasAgGrid";
import colDefs, {
  ColTypes,
} from "shared-modules/vendor_invoices/invoice-list/colDefs";
import useGetInvoices from "shared-modules/vendor_invoices/invoice-list/useGetInvoices";

// const GridComponent = lazy(
//   () =>
//     import(
//       `${
//         isAdminUser
//           ? "../../general-components/ag-grid/GrasEnterpriseAgGrid"
//           : "../../general-components/ag-grid/GrasAgGrid"
//       }`
//     )
// );

type PropTypes = {
  cols: ColTypes[];
  tableKey: string;
  where?: object;
};
const VendorInvoiceList: FC<PropTypes> = ({ cols, tableKey, where }) => {
  const { data, error, loading } = useGetInvoices(cols, where);
  const history = useHistory();

  if (error)
    return (
      <div>
        An error occured{" "}
        <Button type="link" onClick={() => history.push("/")}>
          Go back home
        </Button>
      </div>
    );
  return loading ? (
    <div>Loading...</div>
  ) : (
    <div style={{ height: "100%" }}>
      <GrasAgGrid
        rowData={data?.invoices ?? []}
        tableKey={tableKey}
        columnDefs={cols.map((col) => {
          const { graphQL, ...rest } = colDefs[col];
          return rest;
        })}
      />
    </div>
  );
};

export default VendorInvoiceList;
