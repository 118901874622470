import sortBy from "lodash/sortBy";

import { idGetter } from "./useColDefs";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { isAdminUser } from "shared-modules/globals/globals";
import { Proposal } from "shared-modules/types/types";





interface Property {
  PropertyID: number;
  PropertyNameAbr: string;
  PropertyAddressLine1: string;
  PropertyAddressLine2: string | null;
  PropertyAddressCity: string;
  PropertyAddressStateProvinceCode: string;
  PropertyAddressZipCode: string;
}

interface VendorServiceRecordSchedule {
  id: string;
  completed: boolean;
  schedule_date: string;
}

interface Record {
  property_name_abr: string;
  id: string;
  proposal_end_date: string;
  proposal_number: number;
  proposal_service_id: string;
  proposal_start_date: string;
  service_name: string;
  vendor_id: string;
  vendor_service_price: number;
  schedules: VendorServiceRecordSchedule[];
}

export interface VendorServiceRecord {
  vendor_id: string;
  proposal_id: string;
  proposal_start_date: string;
  proposal_end_date: string;
  proposal_number: number;
  property: Property;
  occurrence: number;
  proposal_service_id: string;
  vendor_service_price: number;
  service_number: number;
  OpportunityServiceID: number;
  id: string;
  occurrences: number;
  service_name: string;
  isSaved: boolean;
  record: Record;
  proposal_service_as_needed: boolean
}


const QUERY = gql`
  query MyQuery(${
    isAdminUser ? `$vendorID: uuid!, ` : ""
  }$fromDate: date!, $toDate: date!) {
    vendorServiceRecords: vendor_service_record(where: {
      _and: [
        ${isAdminUser ? `{vendor_id: { _eq: $vendorID}}` : ""}
        {_or: [
          {_and: [
            {proposal_start_date: {_lte: $toDate}}
            {proposal_end_date: {_gte: $toDate}}
          ]}
          {_and: [
            {proposal_end_date: {_gte: $fromDate}}
            {proposal_start_date: {_lte: $fromDate}}
          ]}
          
        ]}
      ]
    }) {
      property_name_abr
      id
      proposal_end_date
      proposal_number
      proposal_service_id
      proposal_start_date
      service_name
      vendor_id
      vendor_service_price
      schedules(order_by: {schedule_date: asc_nulls_last}) {
        id
        completed
        schedule_date
      }
    }
    vendorProposals(where: {
      _and: [
        ${isAdminUser ? `{vendor_id: { _eq: $vendorID}}` : ""}
        {status: {_eq: "ACCEPTED"}}
        {_or: [
          {_and: [
            {proposal_start_date: {_lte: $toDate}}
            {proposal_end_date: {_gte: $toDate}}
          ]}
          {_and: [
            {proposal_end_date: {_gte: $fromDate}}
            {proposal_start_date: {_lte: $fromDate}}
          ]}
          
        ]}
      ]
    }) {
      proposal_start_date
      proposal_end_date
      proposal_number
      id
      vendor_id
      aspireOpportunity {
        OpportunityID
        OpportunityName
        property {
          PropertyID
          PropertyNameAbr
          PropertyAddressLine1
          PropertyAddressLine2
          PropertyAddressCity
          PropertyAddressStateProvinceCode
          PropertyAddressZipCode
        }
      }
      vendorProposalServices {
        proposal_service_as_needed
        vendor_service_price
        service_number
        OpportunityServiceID
        id
        occurrences
        service_name
      }
    }
  }
`;

const range = (from: number, to: number, step = 1) =>
  from < to && step > 0
    ? [...Array(Math.floor((to - from) / step))].map((_, i) => from + step * i)
    : [];
const useGetActiveProposalServices = (props: {
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs;
}) => {
  const { fromDate, toDate } = props;
  const { data, ...rest } = useQuery<{
    vendorProposals: Proposal[];
    vendorServiceRecords: VendorServiceRecord[];
  }>(QUERY, {
    skip: !fromDate || !toDate,
    variables: {
      fromDate,
      toDate,
    },
  });
  if (!data) return { data: undefined, ...rest };
  const servicesMap: any = {};
  for (let proposal of data?.vendorProposals ?? []) {
    for (let service of proposal.vendorProposalServices ?? []) {
      service.occurrences =
      service.proposal_service_as_needed || !service.occurrences
        ? 1
        : service.occurrences;
      for (let occurrence of range(1, (service.occurrences || 1) + 1)) {
        const serviceRecord = {
          vendor_id: proposal.vendor_id,
          proposal_id: proposal.id,
          proposal_start_date: proposal.proposal_start_date,
          proposal_end_date: proposal.proposal_end_date,
          proposal_number: proposal.proposal_number,
          property: proposal.aspireOpportunity?.property,
          occurrence,
          proposal_service_id: service?.id,
          ...service,
        };
        const recordID = idGetter(serviceRecord);
        serviceRecord.id = recordID;
        servicesMap[recordID.slice(0, recordID.lastIndexOf("-"))] =
          serviceRecord;
      }
    }
  }
  const records = (data?.vendorServiceRecords ?? []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id.slice(0, curr.id.lastIndexOf("-"))]: {
        ...servicesMap[curr.id.slice(0, curr.id.lastIndexOf("-"))],
        isSaved: true,
        record: curr,
      },
    }),
    {} as { [key: string]: any }
  );
  const allServices = sortBy(Object.values({ ...servicesMap, ...records }), [
    "id",
  ]);
  // console.log({ servicesMap, records, allServices });
  return {
    ...rest,
    data: allServices as VendorServiceRecord[],
  };
};

export default useGetActiveProposalServices;
