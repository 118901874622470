export const isAdminUser = process.env?.REACT_APP_PROJECT === 'ADMIN'
export const isVendorUser = process.env?.REACT_APP_PROJECT === 'VENDOR'
export const ONE_HOUR = 60 * 60 * 1000;

export const USERROLES = ["user", "admin", "vendor_mgmt"];

export const VENDOR_PROPOSAL_STAGES = {
  BIDDING: "BIDDING",
  BIDDING_COMPLETE: "BIDDING COMPLETE",
  SUBMITTED: "SUBMITTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};
export enum ACTIVITY_TYPES {
  EMAIL = "email",
  NOTE = "note",
  ATTACHMENT = "attachment",
}
export enum ACTIVITY_RECORD_TYPES {
  // attach attachments to emails
  EMAIL = "email",
  VENDOR = "vendor",
  PROPOSAL = "proposal",
  OPPORTUNITY = "opportunity",
  SERVICE_REPORT = "service-report",
}
export const DEFAULT_SEND_FROM_EMAIL = "service@graslawn.com";

export const PAGE_LINKS = {
  ASPIRE_CONTACT: "/aspire-contact/",
  VENDOR_LINK: "/vendors/vendor/",
  PROPOSAL_LINK: isAdminUser ? "/vendors/proposal/" : "/proposal/",
  OPPORTUNITY_LINK: "/vendors/Opportunity/",
  PROPERTY_LINK: "/property/",
  VENDOR_INVOICE_LINK: isAdminUser ? "/vendors/vendor-invoice/" : isVendorUser ? "/invoice/" : "",
};

export const ASPIRE_PAGE_LINKS = {
  OPPORTUNITY_DETAILS: (OpportunityID: number) =>
    `${process.env.REACT_APP_ASPIRE_BASE_URL}/opportunities/details/${OpportunityID}`,
};

export const VENDOR_INVOICE_STATUSES = {
  EDITING: "editing",
  SUBMITTED: "submitted",
  REVIEWED: "reviewed",
  APPROVED: "approved",
  REJECTED: "rejected",
};
