import React from "react";
import { StandardPage } from "shared-modules/general-components/standard-page/StandardPage";
import InvoiceList from "./invoice-list/InvoiceList";

const ViewAllInvoices = () => {
  return (
    <StandardPage title="Vendor Invoices">
      <InvoiceList
        cols={[
          "vendor_invoice_number",
          "vendor_invoice_provided_invoice_number",
          "property",
          "invoice_date",
          "amount",
          "status",
        ]}
        tableKey="AllInvoices"
        where={{}}
      />
    </StandardPage>
  );
};

export default ViewAllInvoices;
