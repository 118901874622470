import { AgGridReact } from "@ag-grid-community/react";
import { Button, DatePicker, Space } from "antd";
import FormItem from "antd/lib/form/FormItem";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useColDefs from "./useColDefs";
import useGetActiveProposalServices from "./useGetActiveProposalServices";
dayjs.extend(utc)


const AllActiveProposalServices: FC<{ vendorID?: string }> = ({ vendorID }) => {
  const history = useHistory();
  const gridRef = useRef<AgGridReact>(null);
  const [[fromDate, toDate], setDateRange] = useState([dayjs(), dayjs()]);
  const { data, error } = useGetActiveProposalServices({
    fromDate,
    toDate,
  });
  const allServicesColDefs = useColDefs();
  if (error) {
    console.error("AllActiveProposalServices ERROR:", error);
    return (
      <div>
        An error occured{" "}
        <Button type="link" onClick={() => history.push("/")}>
          Go back home
        </Button>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div>
        <Space>
          <FormItem label="Contract active date range">
            <DatePicker.RangePicker
              format="M/D/YYYY"
              value={[fromDate, toDate]}
              onChange={(range) =>
                range && setDateRange([dayjs(range[0]), dayjs(range[1])])
              }
            />
          </FormItem>
          <FormItem>
            <Button onClick={() => gridRef?.current?.api?.exportDataAsCsv()}>
              Export to CSV
            </Button>
          </FormItem>
        </Space>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={allServicesColDefs}
        />
      </div>
    </div>
  );
};

export default AllActiveProposalServices;
