/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const {
  REACT_APP_AWS_PROJECT_REGION,
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_USER_POOLS_ID,
  REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  REACT_APP_AUTH_LINK,
  REACT_APP_REDIRECT_SIGNIN,
  REACT_APP_REDIRECT_SIGNOUT
} = process.env
const awsmobile = {
  aws_project_region: REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
      domain: REACT_APP_AUTH_LINK,
      scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: REACT_APP_REDIRECT_SIGNOUT,
      responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS"
};
export default awsmobile;
