import { useQuery, gql } from "@apollo/client";
import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import { merge } from "lodash";
import colDefs, { ColTypes } from "./colDefs";

export default function useGetInvoices(cols: ColTypes[], where?: object) {
  const columnDefinitions = cols.map((key) => colDefs[key]);
  const query = {
    query: {
      invoices: {
        __aliasFor: "vendor_invoice",
        __args: {
          where,
          order_by: [
            { vendor_invoice_number: new EnumType("desc_nulls_last") },
          ],
        },
      },
    },
  };
  for (let columnDefinition of columnDefinitions) {
    merge(query.query.invoices, columnDefinition.graphQL);
  }
  return useQuery(
    gql`
      ${jsonToGraphQLQuery(query, { pretty: true })}
    `,
    {
      skip: !where,
      notifyOnNetworkStatusChange: true,
    }
  );
}
