import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query {
    vendors {
      vendor_saves_active_services
    }
  }
`;
const useGetVendorSavesRecords = () => {
  const vendorSavesQuery = useQuery(QUERY);
  const data = vendorSavesQuery?.data ? !!vendorSavesQuery.data.vendors[0]?.vendor_saves_active_services : undefined;
  return {
    ...vendorSavesQuery,
    data
  }
};

export default useGetVendorSavesRecords;
